import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress, Grid, Paper } from '@mui/material';

import DestinationMap from '../../../components/Maps/DestinationMap/DestinationMap';
import { getDestinations, getDestinationLocations } from '../../../actions/destinations';
import DestinationTable from '../../../components/Table/Destination/DestinationTable';
import { DisplayDestinationMarkersContext } from '../../../components/Maps/DestinationMap/DisplayDestinationMarkersContext';
import { GoToMarkerContext } from '../../../components/Maps/DestinationMap/GoToMarkerContext';
import { DestinationRowsContext } from '../../../components/Maps/DestinationMap/DestinationRowsContext';
import LoadDestinations from './LoadDestinations';

const Destinations = () => {
  const [displayDestinationMarkersTrigger, setDisplayDestinationMarkersTrigger] = useState(false);
  const [goToMarker, setGoToMarker] = useState(null); // テーブルで位置コードのボタンを押すと、そのマーカーを地図で表示します。
  const [destinationsLoaded, setDestinationsLoaded] = useState(false); // 最初の数秒間、納入先と納入先位置データをDBから取得します。終わったらtrue
  const [destinationRows, setDestinationRows] = useState([]); //現在、テーブルと地図に表示中の納入先
  const [openMapTrigger, setOpenMapTrigger] = useState(true); // 地図開閉トリガー
  const [currentId] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDestinationLocations());
    dispatch(getDestinations());
  }, [currentId, dispatch]);

  const destinations = useSelector((state) => state.data);

  return (
    <DestinationRowsContext.Provider value={{ destinationRows, setDestinationRows}}>
      <GoToMarkerContext.Provider value={{ goToMarker, setGoToMarker }}>
        <DisplayDestinationMarkersContext.Provider value={{ displayDestinationMarkersTrigger, setDisplayDestinationMarkersTrigger }}>
          <LoadDestinations dispatch={dispatch} getDestinations={getDestinations} getDestinationLocations={getDestinationLocations} setDestinationsLoaded={setDestinationsLoaded} />
          <Grid container spacing={3} sx={{ paddingTop: '20px' }}>
            <Grid item xs={12}>
              {openMapTrigger ? (
                destinationsLoaded ? (
                  <Paper>
                    <DestinationMap destinations={destinations} openMapTrigger={openMapTrigger} setOpenMapTrigger={setOpenMapTrigger} />
                  </Paper>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CircularProgress />
                  </div>
                )
              ) : (
                <Paper sx={{ bgcolor: '#e7f3e7' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <DestinationMap destinations={destinations} openMapTrigger={openMapTrigger} setOpenMapTrigger={setOpenMapTrigger} />
                  </div>
                </Paper>
              )}
            </Grid>
            <Grid item xs={12} className="table-wrapper">
              <DestinationTable data={destinations} />
            </Grid>
          </Grid>
        </DisplayDestinationMarkersContext.Provider>
      </GoToMarkerContext.Provider>
    </DestinationRowsContext.Provider>
  );
};

export default Destinations;
