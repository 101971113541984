import { FETCH_PACKING_RESPONSE, FETCH_ALL_PACKING_RESPONSES } from '../constants/actionTypes';
import * as api from '../api/index.js';

export const getPackingResponse = (id) => async (dispatch) => {
  try {
    const { data } = await api.fetchPackingResponse(id);

    dispatch({ type: FETCH_PACKING_RESPONSE, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const getPackingResponses = () => async (dispatch) => {
  try {
    const { data } = await api.fetchPackingResponses();

    dispatch({ type: FETCH_ALL_PACKING_RESPONSES, payload: data });
  } catch (error) {
    console.log(error);
  }
};
