import React from 'react';
import { Paper, Table, TableBody, TableContainer } from '@mui/material';
import { StyledTableCell, StyledTableRow, StyledTableHeaderInBodyCell } from './styles.js';

const RouteDetails = ({ packingResponse }) => {
  const pointList = packingResponse?.pointList;
  const routeFailed = packingResponse?.failureCodes?.includes('2') && packingResponse?.totalDistance === 0;

  // 行き先一覧を用意
  const points = [];
  let pickUpPointCounter = 0;
  let destinationCounter = 0;
  pointList?.forEach((p, i, arr) => {
    let point = {
      header: p.pointType === 1 ? (i === 0 ? '出発' : '到着') + 'デポ' : p.pointType === 2 ? '倉庫' + ++pickUpPointCounter : p.pointType === 3 ? '納入先' + ++destinationCounter : '休憩',
      arrivalTime: i === 0 ? '' : (p.arrivalTime ?? '--:--') + ' 着',
      leaveTime: i === arr.length - 1 ? '' : (p.leaveTime ?? '--:--') + ' 発',
      pointName: p.pointName,
      desiredTime:
        (p.desiredTimeStart || p.desiredTimeEnd ? '指定：' : '') +
        (p.desiredTimeStart ?? '') +
        (p.desiredTimeStart && p.desiredTimeEnd ? '~' : p.desiredTimeStart ? 'から' : '') +
        (p.desiredTimeEnd ?? '') +
        (!p.desiredTimeStart && p.desiredTimeEnd ? 'まで' : ''),
    };
    points.push(point);
  });

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {points.map((p, i) => {
            return (
              <StyledTableRow key={'point' + i}>
                <StyledTableHeaderInBodyCell>{p.header} </StyledTableHeaderInBodyCell>
                <StyledTableCell style={routeFailed ? { color: 'red' } : {}}>{p.arrivalTime}</StyledTableCell>
                <StyledTableCell style={routeFailed ? { color: 'red' } : {}}>{p.leaveTime}</StyledTableCell>
                <StyledTableCell>{p.desiredTime}</StyledTableCell>
                <StyledTableCell>{p.pointName}</StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RouteDetails;
