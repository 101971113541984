import { FETCH_ALL_LOCATIONS, UPDATE_LOCATION, CREATE_LOCATION, DELETE_LOCATION } from '../constants/actionTypes';

const destinationLocationsReducer = (destinationLocations = [], action) => {
  switch (action.type) {
    case CREATE_LOCATION:
      return [...destinationLocations, action.payload];
    case FETCH_ALL_LOCATIONS:
      return action.payload;
    case UPDATE_LOCATION:
      return destinationLocations.map((destinationLocation) => (destinationLocation._id === action.payload._id ? action.payload : destinationLocation));
    case DELETE_LOCATION:
      return destinationLocations.filter((destinationLocations) => destinationLocations._id !== action.payload);
    default:
      return destinationLocations;
  }
};

export default destinationLocationsReducer;
