export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const FETCH_ALL = 'FETCH_ALL';

export const FETCH_ALL_REMARK_NAMES = 'FETCH_ALL_REMARK_NAMES';
export const UPDATE_REMARK_NAMES = 'UPDATE_REMARK_NAMES';

export const CREATE_LOCATION = 'CREATE_LOCATION';
export const FETCH_ALL_LOCATIONS = 'FETCH_ALL_LOCATIONS';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const DELETE_LOCATION = 'DELETE_LOCATION';

export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';
export const VERIFY = 'VERIFY';

export const FETCH_PACKING_RESPONSE = 'FETCH_PACKING_RESPONSE';
export const FETCH_ALL_PACKING_RESPONSES = 'FETCH_ALL_PACKING_RESPONSES';
