export const DestinationRemarkNames = [
  {
    Header: '備考1',
    accessor: 'remark1',
    type: 'text',
    required: true,
    xs: 6,
  },
  {
    Header: '備考2',
    accessor: 'remark2',
    type: 'text',
    required: true,
    xs: 6,
  },
  {
    Header: '備考3',
    accessor: 'remark3',
    type: 'text',
    required: true,
    xs: 6,
  },
  {
    Header: '備考4',
    accessor: 'remark4',
    type: 'text',
    required: true,
    xs: 6,
  },
  {
    Header: '備考5',
    accessor: 'remark5',
    type: 'text',
    required: true,
    xs: 6,
  },
  {
    Header: '備考6',
    accessor: 'remark6',
    type: 'text',
    required: true,
    xs: 6,
  },
  {
    Header: '備考7',
    accessor: 'remark7',
    type: 'text',
    required: true,
    xs: 6,
  },
  {
    Header: '備考8',
    accessor: 'remark8',
    type: 'text',
    required: true,
    xs: 6,
  },
  {
    Header: '備考9',
    accessor: 'remark9',
    type: 'text',
    required: true,
    xs: 6,
  },
  {
    Header: '備考10',
    accessor: 'remark10',
    type: 'text',
    required: true,
    xs: 6,
  },
  {
    Header: '備考11',
    accessor: 'remark11',
    type: 'text',
    required: true,
    xs: 6,
  },
  {
    Header: '備考12',
    accessor: 'remark12',
    type: 'text',
    required: true,
    xs: 6,
  },
  {
    Header: '備考13',
    accessor: 'remark13',
    type: 'text',
    required: true,
    xs: 6,
  },
  {
    Header: '備考14',
    accessor: 'remark14',
    type: 'text',
    required: true,
    xs: 6,
  },
  {
    Header: '備考15',
    accessor: 'remark15',
    type: 'text',
    required: true,
    xs: 6,
  },
  {
    Header: '備考16',
    accessor: 'remark16',
    type: 'text',
    required: true,
    xs: 6,
  },
  {
    Header: '備考17',
    accessor: 'remark17',
    type: 'text',
    required: true,
    xs: 6,
  },
  {
    Header: '備考18',
    accessor: 'remark18',
    type: 'text',
    required: true,
    xs: 6,
  },
  {
    Header: '備考19',
    accessor: 'remark19',
    type: 'text',
    required: true,
    xs: 6,
  },
  {
    Header: '備考20',
    accessor: 'remark20',
    type: 'text',
    required: true,
    xs: 6,
  },
  {
    Header: '備考21',
    accessor: 'remark21',
    type: 'text',
    required: true,
    xs: 6,
  },
  {
    Header: '備考22',
    accessor: 'remark22',
    type: 'text',
    required: true,
    xs: 6,
  },
  {
    Header: '備考23',
    accessor: 'remark23',
    type: 'text',
    required: true,
    xs: 6,
  },
  {
    Header: '備考24',
    accessor: 'remark24',
    type: 'text',
    required: true,
    xs: 6,
  },
  {
    Header: '備考25',
    accessor: 'remark25',
    type: 'text',
    required: true,
    xs: 6,
  },
  {
    Header: '備考26',
    accessor: 'remark26',
    type: 'text',
    required: true,
    xs: 6,
  },
  {
    Header: '備考27',
    accessor: 'remark27',
    type: 'text',
    required: true,
    xs: 6,
  },
  {
    Header: '備考28',
    accessor: 'remark28',
    type: 'text',
    required: true,
    xs: 6,
  },
  {
    Header: '備考29',
    accessor: 'remark29',
    type: 'text',
    required: true,
    xs: 6,
  },
  {
    Header: '備考30',
    accessor: 'remark30',
    type: 'text',
    required: true,
    xs: 6,
  },
  {
    Header: '.',
  },
];
