import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@mui/material';

import { getPackages } from '../../actions/packages';
import DataTable from '../../components/Table/NotDestination/DataTable';
import { PACKAGES } from '../../constants/dataTypes';

const PackageMaster = () => {
  const [currentId] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPackages());
  }, [currentId, dispatch]);

  const packages = useSelector((state) => state.data);

  return (
    <Grid container spacing={3} sx={{ paddingTop: '20px' }}>
      <Grid item xs={12} className="table-wrapper">
        <DataTable data={packages} dataType={PACKAGES} />
      </Grid>
    </Grid>
  );
};

export default PackageMaster;
