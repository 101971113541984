import { FETCH_ALL, CREATE, UPDATE, DELETE } from '../constants/actionTypes';

// デポ、倉庫、納入先、ラックサイズ、車両用の普遍的なアクションタイプ。
const dataReducer = (data = [], action) => {
  switch (action.type) {
    case FETCH_ALL:
      return action.payload;
    case CREATE:
      return [...data, action.payload];
    case UPDATE:
      return data.map((data) => (data._id === action.payload._id ? action.payload : data));
    case DELETE:
      return data.filter((data) => data._id !== action.payload);
    default:
      return data;
  }
};

export default dataReducer;
