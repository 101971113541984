import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Button, Typography, Paper, IconButton, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { DepotMasterColumns } from './Columns/DepotMasterColumns';
import { PickUpPointMasterColumns } from './Columns/PickUpPointMasterColumns';
import { PackageMasterColumns } from './Columns/PackageMasterColumns';
import { TruckMasterColumns } from './Columns/TruckMasterColumns';

import { createDepot, updateDepot } from '../../../actions/depots';
import { createPackage, updatePackage } from '../../../actions/packages';
import { createPickUpPoint, updatePickUpPoint } from '../../../actions/pickUpPoints';
import { createTruck, updateTruck } from '../../../actions/trucks';
import { checkTypeAndRange, setMessageAccordingToDbResult } from '../commonFunctions.js';

import { TRUCKS, PACKAGES, DEPOTS, PICK_UP_POINTS, TRUCKS_JP, PACKAGES_JP, DEPOTS_JP, PICK_UP_POINTS_JP } from '../../../constants/dataTypes';

const DataForm = ({ setMessage, flashTrigger, setFlashTrigger, dataType, currentId, setCurrentId, openForm, setOpenForm, allData }) => {
  const dispatch = useDispatch();

  // データ作成、編集、削除用の変数を用意。画面によって中身を後で決める。
  let createData = () => {};
  let updateData = () => {};

  // 変更時のテキストデータの更新
  const handleChange = async (e, i, d) => {
    setTextData({
      ...textData,
      [d.accessor]: e.target.value,
    });
  };

  // 閉じるボタン
  const handleClose = () => {
    clear();
    setOpenForm(false);
  };

  let dataDetails = useMemo(() => [], []);
  // eslint-disable-next-line no-new-object
  let dataObject = useMemo(() => new Object(), []);
  let dataTypeJP = '';
  const [textData, setTextData] = useState(dataObject);

  // クリアボタン
  const clear = useCallback(() => {
    setIsError(new Array(dataDetails.length - 1).fill(false));
    setErrorHelper(new Array(dataDetails.length - 1).fill(''));
    setCurrentId(0);
    setTextData(dataObject);
    setSubmitDisabled(true);
  }, [dataObject, setCurrentId, dataDetails.length]);

  const [submitDisabled, setSubmitDisabled] = useState(true);

  const data = useSelector((state) => (currentId ? state.data.find((d) => d._id === currentId) : null));

  // 画面によって作成、編集、削除の動作を決める
  switch (dataType) {
    case TRUCKS:
      dataDetails = TruckMasterColumns;
      dataTypeJP = TRUCKS_JP;
      createData = () => dispatch(createTruck(textData));
      updateData = () => dispatch(updateTruck(currentId, textData));
      break;
    case DEPOTS:
      dataDetails = DepotMasterColumns;
      dataTypeJP = DEPOTS_JP;
      createData = () => dispatch(createDepot(textData));
      updateData = () => dispatch(updateDepot(currentId, textData));
      break;
    case PACKAGES:
      dataDetails = PackageMasterColumns;
      dataTypeJP = PACKAGES_JP;
      createData = () => dispatch(createPackage(textData));
      updateData = () => dispatch(updatePackage(currentId, textData));
      break;
    case PICK_UP_POINTS:
      dataDetails = PickUpPointMasterColumns;
      dataTypeJP = PICK_UP_POINTS_JP;
      createData = () => dispatch(createPickUpPoint(textData));
      updateData = () => dispatch(updatePickUpPoint(currentId, textData));
      break;
    default:
      break;
  }
  // キー＝各カラムのaccessor（名前）、値＝空のデータ、のオブジェクトを用意する。
  for (let i = 0; i < dataDetails.length - 1; i++) {
    dataObject[dataDetails[i].accessor] = dataDetails[i].type === 'number' ? 0 : '';
  }

  const [isError, setIsError] = useState(new Array(dataDetails.length - 1).fill(false));
  const [errorHelper, setErrorHelper] = useState(new Array(dataDetails.length - 1).fill(''));
  // 送信ボタン
  const handleSubmit = async (e) => {
    e.preventDefault();
    const isErrorCopy = JSON.parse(JSON.stringify(isError));
    const errorHelperCopy = JSON.parse(JSON.stringify(errorHelper));

    // データ範囲を確認する
    checkTypeAndRange(dataDetails, textData, allData, currentId, isErrorCopy, setIsError, errorHelperCopy, setErrorHelper);
    let isOK = true;
    isErrorCopy.every((e) => {
      if (e) {
        isOK = false;
        return false;
      }
      return true;
    });
    if (!isOK) {
      return;
    }

    if (currentId === 0) {
      // 新規データの作成
      const dbResult = await createData();
      setMessageAccordingToDbResult(dbResult, currentId, setMessage, clear);
    } else {
      // データ更新
      const dbResult = await updateData();
      setMessageAccordingToDbResult(dbResult, currentId, setMessage, clear);
      handleClose();
    }

    // フラッシュメッセージを表示
    setFlashTrigger(flashTrigger + 1);
  };

  // テキストデータオブジェクトへの書き込み
  useEffect(() => {
    if (data) {
      const { _id, ...dataWithout_id } = data;

      setTextData(dataWithout_id);
    } else clear();
  }, [data, clear]);

  // 必須データが入力されているか否かで、送信ボタンの活性状態を更新する。
  useEffect(() => {
    // 全ての必須データが記入されていますか？
    let areAllRequiredFieldsFilledIn = true;

    if (textData) {
      for (let i = 0; i < Object.keys(textData).length; i++) {
        // データ毎に必須かどうか調べる
        let dataDetailsRequired;
        dataDetails.every((d) => {
          if (d.accessor === Object.keys(textData)[i]) {
            dataDetailsRequired = d.required;
            return false;
          }
          return true;
        });

        if ((Object.values(textData)[i] === '' || Object.values(textData)[i] == null) && dataDetailsRequired) {
          // 全ての必須データが記入されていません。
          areAllRequiredFieldsFilledIn = false;
          break;
        }
      }
    }
    // 活性状態を更新する。
    setSubmitDisabled(!areAllRequiredFieldsFilledIn);
  }, [textData, dataDetails]);

  return openForm ? (
    <Paper style={{ maxHeight: '90%', overflow: 'auto' }} elevation={18} sx={{ width: '33%' }}>
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container sx={{ paddingBottom: '20px', paddingLeft: '20px', paddingRight: '20px', paddingTop: '10px' }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={0}>
                <Grid item xs={11}>
                  <Typography sx={{ padding: '10px' }} variant="h6">
                    {currentId ? `${dataTypeJP}を編集する` : `新規${dataTypeJP}を作成する`}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={handleClose}>
                    <CloseIcon sx={{ color: 'red' }} fontSize="large" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {dataDetails.map((d, i, details) => {
                  return i !== details.length - 1 ? (
                    <React.Fragment key={details[i].accessor}>
                      <Grid item xs={d.xs ?? 12}>
                        <TextField
                          error={isError[i]}
                          helperText={errorHelper[i]}
                          size={'normal'}
                          type={'text'} // type number の動きが少しおかしいので、全てtextにする
                          name={d.accessor}
                          required={d.required}
                          disabled={d.disabled}
                          variant="outlined"
                          label={d.Header}
                          fullWidth
                          onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
                          value={textData[d.accessor] ?? ''}
                          onChange={(e) => handleChange(e, i, d)}
                        />
                      </Grid>
                    </React.Fragment>
                  ) : null;
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ backgroundColor: '#ffffff', zIndex: '2', paddingBottom: '1rem', paddingLeft: '1rem', paddingRight: '1rem', position: 'sticky', bottom: '0rem' }}>
          <Grid item xs={6}>
            <Button className={'buttonSubmit'} disabled={submitDisabled} variant="contained" color="primary" size="large" type="submit" fullWidth>
              {currentId ? '編集' : '新規作成'}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" color="secondary" onClick={handleClose} fullWidth>
              キャンセル
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  ) : (
    ''
  );
};

export default DataForm;
