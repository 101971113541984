import { AUTH, VERIFY } from '../constants/actionTypes';
import * as api from '../api/index.js';

export const signin = (formData, navigate) => async (dispatch) => {
  try {
    const { data } = await api.signIn(formData);

    dispatch({ type: AUTH, data });

    navigate('/');
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const signup = (formData, navigate) => async (dispatch) => {
  try {
    const { data } = await api.signUp(formData);

    dispatch({ type: AUTH, data });

    navigate('/');
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const verify = (token) => async (dispatch) => {
  try {
    const { data } = await api.verify(token);

    dispatch({ type: VERIFY, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const verifyWithId = (info) => async (dispatch) => {
  try {
    const { data } = await api.verifyWithId(info);

    dispatch({ type: VERIFY, payload: data });
  } catch (error) {
    console.log(error);
  }
};
