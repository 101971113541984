import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Avatar, Button, Paper, Grid, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import { ERROR, SUCCESS_LOGIN, SUCCESS_PASSWORD_CREATE } from '../../constants/flashMessages';

import { signin, signup } from '../../actions/auth';
import Input from './Input';

const initialState = {
  password: '',
  confirmPassword: '',
};

const SignUp = ({ setMessage, flashTrigger, setFlashTrigger }) => {
  const [form, setForm] = useState(initialState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSignup, setIsSignup] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);
  const handleShowRepeatPassword = () => setShowRepeatPassword(!showRepeatPassword);

  const switchMode = () => {
    setForm(initialState);
    setIsSignup((prevIsSignup) => !prevIsSignup);
    setShowPassword(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let isSuccessful;
      if (isSignup) {
        isSuccessful = await dispatch(signup(form, navigate));
        setMessage(isSuccessful ? SUCCESS_PASSWORD_CREATE : ERROR);
      } else {
        isSuccessful = await dispatch(signin(form, navigate));
        setMessage(isSuccessful ? SUCCESS_LOGIN : ERROR);
      }

      setFlashTrigger(flashTrigger + 1);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value });

  return (
    <Container component="main" maxWidth="xs">
      <Paper className={'paper'} elevation={6} sx={{ marginTop: '25px', padding: '20px' }}>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12} sx={{ margin: '35px' }}>
            <Avatar className={'avatar'} sx={{ bgcolor: '#ff5252' }}>
              <LockIcon />
            </Avatar>
          </Grid>
          <Grid item xs={12}>
            <form className={form} onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Input name="password" label="パスワード" handleChange={handleChange} type={showPassword ? 'text' : 'password'} handleShowPassword={handleShowPassword} />
                    {isSignup && (
                      <Input
                        name="confirmPassword"
                        label="パスワードを再度入力"
                        handleChange={handleChange}
                        type={showRepeatPassword ? 'text' : 'password'}
                        handleShowPassword={handleShowRepeatPassword}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" fullWidth variant="contained" color="primary" className={'submit'}>
                    {isSignup ? 'パスワードを設定する' : 'ログイン'}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button onClick={switchMode}>{isSignup ? 'パスワード設定済の場合、ログイン' : 'パスワードが未設定の場合、作成する'}</Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default SignUp;
