import { highlightableCell, shortenLatLng } from '../../commonFunctions.js';

export const DepotMasterColumns = [
  {
    Header: 'デポコード',
    accessor: 'depotCode',
    accessorForCsv: 'depotCode',
    type: 'text',
    required: true,
    Cell: highlightableCell,
    percentWidth: '18%',
  },
  {
    Header: 'デポ名称',
    accessor: 'depotName',
    accessorForCsv: 'depotName',
    type: 'text',
    required: true,
    Cell: highlightableCell,
    percentWidth: '36%',
  },
  {
    Header: 'デポ緯度',
    accessor: 'depotLat',
    accessorForCsv: 'depotLat',
    disableGlobalFilter: true,
    type: 'number',
    required: true,
    Cell: ({ value }) => shortenLatLng(value),
    percentWidth: '17%',
  },
  {
    Header: 'デポ経度',
    accessor: 'depotLng',
    accessorForCsv: 'depotLng',
    disableGlobalFilter: true,
    type: 'number',
    required: true,
    Cell: ({ value }) => shortenLatLng(value),
    percentWidth: '17%',
  },
  {
    Header: '.',
    accessor: '_id',
    disableGlobalFilter: true,
    percentWidth: '12%',
  },
];
