import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getPackingResponse } from '../../actions/packingResponses.js';
import { verify } from '../../actions/auth.js';
import { Grid } from '@mui/material';
import './packing3D.css';
import VerifyToken from '../../components/Packing3D/VerifyToken.jsx';
import ResultBar from '../../components/Packing3D/ResultBar.jsx';
import RouteHeader from '../../components/Packing3D/RouteHeader.jsx';
import RouteDetails from '../../components/Packing3D/RouteDetails.jsx';
import PackageDetails from '../../components/Packing3D/PackageDetails.jsx';
import TruckDisplay from '../../components/Packing3D/TruckDisplay.jsx';

const Packing3D = () => {
  const { resultId, overlappingHeight, token } = useParams();
  const [isValidToken, setIsValidToken] = useState(false);
  const [hasTokenBeenChecked, setHasTokenBeenChecked] = useState(false);
  const [selectedPackageRow, setSelectedPackageRow] = useState(null);
  const dispatch = useDispatch();

  const packingResponse = useSelector((state) => state.packingResponses);

  return (
    <>
      <VerifyToken
        dispatch={dispatch}
        verify={verify}
        token={token}
        setIsValidToken={setIsValidToken}
        setHasTokenBeenChecked={setHasTokenBeenChecked}
        getPackingResponse={getPackingResponse}
        resultId={resultId}
      />
      {isValidToken ? (
        <Grid container spacing={3} sx={{ paddingTop: '20px' }}>
          <Grid item xs={12}>
            <ResultBar resultId={resultId} packingResponse={packingResponse} />
          </Grid>
          <Grid item xs={7}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <RouteHeader packingResponse={packingResponse} />
              </Grid>
              <Grid item xs={12}>
                <RouteDetails packingResponse={packingResponse} />
              </Grid>
              <Grid item xs={12}>
                <PackageDetails packingResponse={packingResponse} setSelectedPackageRow={setSelectedPackageRow} selectedPackageRow={selectedPackageRow}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <TruckDisplay packingResponse={packingResponse} overlappingHeight={overlappingHeight} selectedPackageRow={selectedPackageRow}/>
          </Grid>
        </Grid>
      ) : (
        <>{hasTokenBeenChecked && 'エラー：URLが無効です。'}</>
      )}
    </>
  );
};

export default Packing3D;
