import React, { useState, useMemo, useRef, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Marker, Popup } from 'react-leaflet';

import { RiSave3Fill } from 'react-icons/ri';
import { BsFillPinMapFill } from 'react-icons/bs';
import { FaUndoAlt } from 'react-icons/fa';
import { IconButton, Tooltip } from '@mui/material';

import { updateDestinationLocation } from '../../../actions/destinations';

import { redIcon, blueIcon, blackIcon, yellowIcon } from '../MarkerIcons.js';
import { ERROR, SUCCESS_EDIT } from '../../../constants/flashMessages';
import { LatLng } from 'leaflet';
import { DB_SUCCESS } from '../../../constants/dbResultCodes';

const DestinationMapDraggableMarker = ({
  setFlashTrigger,
  setMessage,
  flashTrigger,
  setDraggingDestinationLocation,
  draggingDestinationLocation,
  destinationsWithSameLocationCode,
  setDisplayDestinationMarkersTrigger,
  initialPosition,
}) => {
  const dispatch = useDispatch();

  const [colorIcon, setColorIcon] = useState(blackIcon);

  const [draggable, setDraggable] = useState(destinationsWithSameLocationCode[0]._id === draggingDestinationLocation?._id);

  const getColorIcon = useCallback((waitingPackageNum) => {
    if (waitingPackageNum >= 12) {
      return blueIcon;
    } else if (waitingPackageNum > 0) {
      return yellowIcon;
    } else {
      return blackIcon;
    }
  }, []);

  useEffect(() => {
    if (!draggable) {
      setColorIcon(getColorIcon(destinationsWithSameLocationCode[1].waitingPackageNum));
    }
  }, [destinationsWithSameLocationCode, draggable, getColorIcon]);

  const [position, setPosition] = useState(
    destinationsWithSameLocationCode[0]._id === draggingDestinationLocation?._id
      ? new LatLng(destinationsWithSameLocationCode[0].destinationLat, destinationsWithSameLocationCode[0].destinationLng)
      : initialPosition,
  );

  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          const markerLatLng = marker.getLatLng();
          setPosition(markerLatLng);
          const updatedDraggingDestinationLocation = {
            ...draggingDestinationLocation,
            destinationLat: markerLatLng.lat,
            destinationLng: markerLatLng.lng,
          };
          setDraggingDestinationLocation(updatedDraggingDestinationLocation);
        }
      },
    }),
    [draggingDestinationLocation, setDraggingDestinationLocation],
  );

  const toggleDraggable = useCallback(() => {
    setDraggable((d) => !d);
    setDraggingDestinationLocation(!draggingDestinationLocation ? JSON.parse(JSON.stringify(destinationsWithSameLocationCode[0])) : null);
    setDisplayDestinationMarkersTrigger(true);
  }, [setDraggingDestinationLocation, draggingDestinationLocation, destinationsWithSameLocationCode, setDisplayDestinationMarkersTrigger]);

  const undoDrag = () => {
    toggleDraggable();
    setPosition(initialPosition);
  };

  const saveNewPosition = async () => {
    const updatedLocation = JSON.parse(JSON.stringify(destinationsWithSameLocationCode[0]));
    updatedLocation.destinationLat = position.lat;
    updatedLocation.destinationLng = position.lng;
    const result = await dispatch(updateDestinationLocation(updatedLocation._id, updatedLocation));
    toggleDraggable();
    let message;
    if (result === DB_SUCCESS) {
      message = SUCCESS_EDIT;
    } else {
      message = ERROR;
      undoDrag();
    }
    setMessage(message);
    setFlashTrigger(flashTrigger + 1);
    setDraggingDestinationLocation(null);
  };

  const ToolTipIconButton = () => {
    return (
      <>
        <Tooltip title={draggingDestinationLocation ? draggable ? <div>保存</div> : <div>別マーカが移動中</div> : <div>マーカ移動</div>}>
          <span>
            <IconButton
              style={{ color: draggingDestinationLocation && !draggable ? 'gray' : 'black' }}
              size="small"
              onClick={draggable ? saveNewPosition : toggleDraggable}
              disabled={draggingDestinationLocation && !draggable}
            >
              {draggable ? <RiSave3Fill /> : <BsFillPinMapFill />}
            </IconButton>
          </span>
        </Tooltip>
        {draggable ? (
          <Tooltip title={<div>元に戻す</div>}>
            <IconButton style={{ color: 'black' }} size="small" onClick={undoDrag}>
              <FaUndoAlt />
            </IconButton>
          </Tooltip>
        ) : (
          ''
        )}
      </>
    );
  };

  return (
    <>
      <Marker key={destinationsWithSameLocationCode[1]._id} draggable={draggable} eventHandlers={eventHandlers} position={position} ref={markerRef} icon={draggable ? redIcon : colorIcon}>
        <Popup sx={{ whiteSpace: 'pre-wrap' }}>
          <ToolTipIconButton sx={{ marginBottom: '5px' }} />
          　位置コード：{destinationsWithSameLocationCode[0].destinationLocationCode}
          {destinationsWithSameLocationCode.map((d, i) => {
            return (
              i > 0 && (
                <div key={d.destinationLocationCode + i}>
                  <img src={getColorIcon(d.waitingPackageNum).options.iconUrl} alt="icon" height="20px" />　{d.destinationCode}：{d.destinationName}
                </div>
              )
            );
          })}
        </Popup>
      </Marker>
    </>
  );
};

export default DestinationMapDraggableMarker;
