import { highlightableCell } from '../../commonFunctions.js';

export const DestinationMasterColumns = [
  {
    Header: '納入先コード',
    accessor: 'destinationCode',
    accessorForCsv: 'destinationCode',
    type: 'text',
    required: true,
    xs: 6,
    Cell: highlightableCell,
    width: 120,
  },
  {
    Header: '納入先名称',
    accessor: 'destinationName',
    accessorForCsv: 'destinationName',
    type: 'text',
    required: true,
    xs: 6,
    Cell: highlightableCell,
    width: 400,
  },
  {
    Header: '納入先位置コード',
    accessor: 'destinationLocationCode',
    accessorForCsv: 'destinationLocationCode',
    disableGlobalFilter: true,
    type: 'select',
    required: true,
    xs: 4,
    width: 150,
  },
  {
    Header: '納入先緯度',
    accessor: 'destinationLat',
    accessorForCsv: 'destinationLat',
    disableGlobalFilter: true,
    type: 'number',
    required: true,
    disabled: true,
    xs: 4,
    width: 120,
  },
  {
    Header: '納入先経度',
    accessor: 'destinationLng',
    accessorForCsv: 'destinationLng',
    disableGlobalFilter: true,
    type: 'number',
    required: true,
    disabled: true,
    xs: 4,
    width: 120,
  },
  {
    Header: '指定時刻（から）',
    accessor: 'desiredTimeStart',
    accessorForCsv: 'desiredTimeStart',
    disableGlobalFilter: true,
    type: 'time',
    xs: 3,
    width: 0,
  },
  {
    Header: '指定時刻（まで）',
    accessor: 'desiredTimeEnd',
    accessorForCsv: 'desiredTimeEnd',
    disableGlobalFilter: true,
    type: 'time',
    xs: 3,
    width: 125,
  },
  {
    Header: '許可車両（半角カンマ区切り）',
    accessor: 'destinationPermittedTruckTypeCodes',
    accessorForCsv: 'destinationPermittedTruckTypeCodes',
    disableGlobalFilter: true,
    type: 'select',
    xs: 4,
    width: 240,
  },
  {
    Header: '仕掛個体数',
    accessor: 'waitingPackageNum',
    accessorForCsv: 'waitingPackageNum',
    disableGlobalFilter: true,
    type: 'number',
    xs: 2,
    width: 105,
  },
  {
    Header: '備考1',
    accessor: 'remark1',
    name: 'remark1',
    disableGlobalFilter: true,
    type: 'text',
    xs: 6,
    isEditable: true,
  },
  {
    Header: '備考2',
    accessor: 'remark2',
    name: 'remark2',
    disableGlobalFilter: true,
    type: 'text',
    xs: 6,
    isEditable: true,
  },
  {
    Header: '備考3',
    accessor: 'remark3',
    name: 'remark3',
    disableGlobalFilter: true,
    type: 'text',
    xs: 6,
    isEditable: true,
  },
  {
    Header: '備考4',
    accessor: 'remark4',
    name: 'remark4',
    disableGlobalFilter: true,
    type: 'text',
    xs: 6,
    isEditable: true,
  },
  {
    Header: '備考5',
    accessor: 'remark5',
    name: 'remark5',
    disableGlobalFilter: true,
    type: 'text',
    xs: 6,
    isEditable: true,
  },
  {
    Header: '備考6',
    accessor: 'remark6',
    name: 'remark6',
    disableGlobalFilter: true,
    type: 'text',
    xs: 6,
    isEditable: true,
  },
  {
    Header: '備考7',
    accessor: 'remark7',
    name: 'remark7',
    disableGlobalFilter: true,
    type: 'text',
    xs: 6,
    isEditable: true,
  },
  {
    Header: '備考8',
    accessor: 'remark8',
    name: 'remark8',
    disableGlobalFilter: true,
    type: 'text',
    xs: 6,
    isEditable: true,
  },
  {
    Header: '備考9',
    accessor: 'remark9',
    name: 'remark9',
    disableGlobalFilter: true,
    type: 'text',
    xs: 6,
    isEditable: true,
  },
  {
    Header: '備考10',
    accessor: 'remark10',
    name: 'remark10',
    disableGlobalFilter: true,
    type: 'text',
    xs: 6,
    isEditable: true,
  },
  {
    Header: '備考11',
    accessor: 'remark11',
    name: 'remark11',
    disableGlobalFilter: true,
    type: 'text',
    xs: 6,
    isEditable: true,
  },
  {
    Header: '備考12',
    accessor: 'remark12',
    name: 'remark12',
    disableGlobalFilter: true,
    type: 'text',
    xs: 6,
    isEditable: true,
  },
  {
    Header: '備考13',
    accessor: 'remark13',
    name: 'remark13',
    disableGlobalFilter: true,
    type: 'text',
    xs: 6,
    isEditable: true,
  },
  {
    Header: '備考14',
    accessor: 'remark14',
    name: 'remark14',
    disableGlobalFilter: true,
    type: 'text',
    xs: 6,
    isEditable: true,
  },
  {
    Header: '備考15',
    accessor: 'remark15',
    name: 'remark15',
    disableGlobalFilter: true,
    type: 'text',
    xs: 6,
    isEditable: true,
  },
  {
    Header: '備考16',
    accessor: 'remark16',
    name: 'remark16',
    disableGlobalFilter: true,
    type: 'text',
    xs: 6,
    isEditable: true,
  },
  {
    Header: '備考17',
    accessor: 'remark17',
    name: 'remark17',
    disableGlobalFilter: true,
    type: 'text',
    xs: 6,
    isEditable: true,
  },
  {
    Header: '備考18',
    accessor: 'remark18',
    name: 'remark18',
    disableGlobalFilter: true,
    type: 'text',
    xs: 6,
    isEditable: true,
  },
  {
    Header: '備考19',
    accessor: 'remark19',
    name: 'remark19',
    disableGlobalFilter: true,
    type: 'text',
    xs: 6,
    isEditable: true,
  },
  {
    Header: '備考20',
    accessor: 'remark20',
    name: 'remark20',
    disableGlobalFilter: true,
    type: 'text',
    xs: 6,
    isEditable: true,
  },
  {
    Header: '備考21',
    accessor: 'remark21',
    name: 'remark21',
    disableGlobalFilter: true,
    type: 'text',
    xs: 6,
    isEditable: true,
  },
  {
    Header: '備考22',
    accessor: 'remark22',
    name: 'remark22',
    disableGlobalFilter: true,
    type: 'text',
    xs: 6,
    isEditable: true,
  },
  {
    Header: '備考23',
    accessor: 'remark23',
    name: 'remark23',
    disableGlobalFilter: true,
    type: 'text',
    xs: 6,
    isEditable: true,
  },
  {
    Header: '備考24',
    accessor: 'remark24',
    name: 'remark24',
    disableGlobalFilter: true,
    type: 'text',
    xs: 6,
    isEditable: true,
  },
  {
    Header: '備考25',
    accessor: 'remark25',
    name: 'remark25',
    disableGlobalFilter: true,
    type: 'text',
    xs: 6,
    isEditable: true,
  },
  {
    Header: '備考26',
    accessor: 'remark26',
    name: 'remark26',
    disableGlobalFilter: true,
    type: 'text',
    xs: 6,
    isEditable: true,
  },
  {
    Header: '備考27',
    accessor: 'remark27',
    name: 'remark27',
    disableGlobalFilter: true,
    type: 'text',
    xs: 6,
    isEditable: true,
  },
  {
    Header: '備考28',
    accessor: 'remark28',
    name: 'remark28',
    disableGlobalFilter: true,
    type: 'text',
    xs: 6,
    isEditable: true,
  },
  {
    Header: '備考29',
    accessor: 'remark29',
    name: 'remark29',
    disableGlobalFilter: true,
    type: 'text',
    xs: 6,
    isEditable: true,
  },
  {
    Header: '備考30',
    accessor: 'remark30',
    name: 'remark30',
    disableGlobalFilter: true,
    type: 'text',
    xs: 6,
    isEditable: true,
  },
  {
    Header: '.',
    accessor: '_id',
    sticky: 'right',
    disableGlobalFilter: true,
    width: 80,
  },
];
