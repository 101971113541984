import React, { useState, useEffect, useRef, useCallback, useContext, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { MapContainer, TileLayer, useMap, useMapEvents } from 'react-leaflet';
import { LatLng, LatLngBounds } from 'leaflet';
import DestinationMapDraggableMarker from './DestinationMapDraggableMarker';
import FlashMessage from '../../Messages/FlashMessage';
import { Backdrop, IconButton } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import MapCloseButton from './MapCloseButton/MapCloseButton';
import './MapCloseButton/MapCloseButton.css';
import RightClickMenu from './RightClickMenu';
import DestinationForm from '../../Table/Destination/DestinationForm';
import MapAddressBox from './MapAddressBox';
import SmoothModeSwich from './SmoothModeSwich';
import { DisplayDestinationMarkersContext } from './DisplayDestinationMarkersContext';
import { fetchGeocoding } from '../../../api';
import { GoToMarkerContext } from './GoToMarkerContext';
import { DestinationRowsContext } from './DestinationRowsContext';

let position = [36.7454436, 137.2276794];

const SMOOTH_MODE_LOCATION_MAX = 700;

const DestinationMap = ({ destinations, openMapTrigger, setOpenMapTrigger }) => {
  const destinationLocations = useSelector((state) => state.destinationLocations);

  // Flash Message
  const [message, setMessage] = useState('');
  const [flashTrigger, setFlashTrigger] = useState(50);

  //軽量モードのチェックボックス
  const [isSmoothMode, setIsSmoothMode] = useState(true);

  // 地図を開く処理
  const handleOpen = () => {
    setOpenMapTrigger(true);
  };

  // 地図の初期の位置をdestinations[0]の位置にする。
  if (destinations.length > 0 && destinationLocations.length > 0) {
    let location = destinationLocations.find((o) => o.destinationLocationCode === destinations[0].destinationLocationCode);
    if (location !== undefined) {
      position = [location.destinationLat, location.destinationLng];
    }
  }

  // テーブルで位置ボタンを押すと、地図でその納入先位置のマーカーを表示します
  const { goToMarker, setGoToMarker } = useContext(GoToMarkerContext);
  const GoToMarker = () => {
    const map = useMap();
    useEffect(() => {
      if (goToMarker) {
        map.setView([goToMarker?.destinationLat, goToMarker?.destinationLng], 18, { animate: false });
        setGoToMarker(null);
      }
    }, [map]);
  };

  //フィルターの絞り込みでテーブルの表示内容が変わったとき、地図にも反映させます
  const { destinationRows } = useContext(DestinationRowsContext);
  useEffect(()=>{
    setDisplayDestinationMarkersTrigger(true);
  },[destinationRows]);

  /** -------------------------
   * |                         |
   * |    RIGHT CLICK MENU     |
   * |                         |
   *  ------------------------- */
  const [openForm, setOpenForm] = useState(false);
  const [newLatLng, setNewLatLng] = useState([0, 0]);
  const [currentId, setCurrentId] = useState(0);
  const [showMenu, setShowMenu] = useState(false);

  // クリックしたところの画面のXY座標
  const [windowX, setWindowX] = useState(0);
  const [windowY, setWindowY] = useState(0);

  const mapRef = useRef(null);

  const mapDivRef = useRef(null);
  const menuRef = useRef(null);

  const [isMap, setIsMap] = useState(false); // クリックしたところは地図か？
  const [isMenu, setIsMenu] = useState(false); // クリックしたところはメニューか？

  useClickRef(mapDivRef, setIsMap);
  useClickRef(menuRef, setIsMenu);

  function useClickRef(ref, setFunc) {
    useEffect(() => {
      function handleClick(event) {
        setFunc(ref.current && ref.current.contains(event.target));
      }
      document.addEventListener('mousedown', handleClick);
      return () => {
        document.removeEventListener('mousedown', handleClick);
      };
    }, [ref, setFunc]);
  }

  function RightClickLatLngSetter() {
    useMapEvents({
      mousedown: (e) => {
        setShowMenu(false);
      },
      contextmenu: (e) => {
        setNewLatLng([e.latlng.lat, e.latlng.lng]);
        setWindowX(e.originalEvent.pageX);
        setWindowY(e.originalEvent.pageY);
        setShowMenu(true);
      },
      // ズームすると、メニューを非表示にする
      zoomstart: () => {
        setIsMap(false);
        setIsMenu(false);
      },
    });
    return null;
  }

  // 画面サイズが変わる時、右クリックメニューを非表示にする。
  const [width, height] = useWindowSize();
  useEffect(() => {
    if (width || height) setShowMenu(false);
  }, [width, height]);

  /** -------------------------
   * |                         |
   * |     ADDRESS SEARCH      |
   * |                         |
   *  ------------------------- */
  const [address, setAddress] = useState(''); // Geocodingの結果
  const [mapTopLeftXY, setMapTopLeftXY] = useState([0, 0]); // 地図の左上のoffsetを取得する（正確な位置にメニューを表示するように）
  const [moveMapTrigger, setMoveMapTrigger] = useState(false); // trueならnewLatLngの位置を地図で表示する
  useEffect(() => {
    if (address !== '') {
      const { offsetLeft, offsetTop } = mapDivRef.current;
      setMapTopLeftXY([offsetLeft, offsetTop]);
      const fetchLatLng = async () => {
        const results = await fetchGeocoding(address);
        setAddress('');
        return results.data.results.length > 0 ? results.data.results[0].geometry.location : null;
      };
      fetchLatLng().then(function (result) {
        if (result) {
          setNewLatLng([result.lat, result.lng]);
          console.log(result);
          setMoveMapTrigger(true);
        } else {
          console.log('Adress not found');
        }
      });
    }
  }, [address, newLatLng]);

  // 検索した結果を地図で表示する
  const MapSetView = () => {
    const map = useMap();
    useEffect(() => {
      if (moveMapTrigger) {
        map.setView([newLatLng[0], newLatLng[1]], 18, { animate: false });
        setMoveMapTrigger(false);
        const windowXY = map.latLngToContainerPoint(new LatLng(newLatLng[0], newLatLng[1]));
        setWindowX(windowXY.x + mapTopLeftXY[0]);
        setWindowY(windowXY.y + mapTopLeftXY[1]);
        setShowMenu(true);
        setIsMap(true);
      }
    }, [map]);
  };

  /** -------------------------
   * |                         |
   * |     DISPLAY MARKERS     |
   * |                         |
   *  ------------------------- */
  const [draggingDestinationLocation, setDraggingDestinationLocation] = useState(null); // 移動中のマーカーの位置情報
  const [markers, setMarkers] = useState([]); // 見えるマーカーの配列
  const { displayDestinationMarkersTrigger, setDisplayDestinationMarkersTrigger } = useContext(DisplayDestinationMarkersContext); // マーカー表示トリガー

  const displayMarkers = useCallback(() => {
    if (!mapRef.current) return;
    const map = mapRef.current.target;
    const destinationLocationsCopy = JSON.parse(JSON.stringify(destinationLocations));

    let initialPositionOfDraggingLocation; // 移動中マーカーの初期位置
    // ドラッグ中の納入先位置が存在するなら、納入先リストにドラッグ中の緯度経度で情報を書き換える
    if (draggingDestinationLocation) {
      let index;
      destinationLocationsCopy.every((e, i) => {
        if (e._id === draggingDestinationLocation._id) {
          index = i;
          return false;
        }
        return true;
      });
      initialPositionOfDraggingLocation = new LatLng(destinationLocationsCopy[index].destinationLat, destinationLocationsCopy[index].destinationLng);
      destinationLocationsCopy[index] = draggingDestinationLocation;
    }
    // 見える納入先
    let searchBounds = map.getBounds();
    let visibleLocations = destinationLocationsCopy.filter((d) => searchBounds.contains(new LatLng(d.destinationLat, d.destinationLng)));

    if(isSmoothMode){
      //マーカーの個数が多くなると動作が重くなるので既定の個数になるまで探索範囲を狭める
      for(let i=0; i<10; i++){
        if(visibleLocations.length <= SMOOTH_MODE_LOCATION_MAX) break;
        let newNorth = (searchBounds.getNorth() * 3 + searchBounds.getCenter().lat)/4;
        let newWest = (searchBounds.getWest() * 3 + searchBounds.getCenter().lng)/4;
        let newSouth = (searchBounds.getSouth() * 3 + searchBounds.getCenter().lat)/4;
        let newEast= (searchBounds.getEast() * 3 + searchBounds.getCenter().lng)/4;
        let newSearchBounds = new LatLngBounds (
          new LatLng(newNorth, newWest),
          new LatLng(newSouth, newEast),
        )
        visibleLocations = destinationLocationsCopy.filter((d) => newSearchBounds.contains(new LatLng(d.destinationLat, d.destinationLng)));
        searchBounds = newSearchBounds;
      }
    }

    //見える納入先リストにあるものだけを抽出
    const visibleDestinations = JSON.parse(JSON.stringify(destinationRows))
      .filter((d) => visibleLocations.find((e) => e.destinationLocationCode === d.destinationLocationCode))
      .sort((a, b) => b.waitingPackageNum - a.waitingPackageNum); // 仕掛個体数の降順（一番多い個体数の納入先が主マーカーになるように）

    // 以下の処理：見える納入先をオブジェクトの形に変えます。
    // オブジェクトのキーは可視納入先の全ての納入先位置コードです。
    // キー毎の値は配列です。配列の中身は[0]=納入先位置情報、[1]~[n]=その納入先位置の納入先情報
    const visibleDestinationsGroupedByLocation = visibleDestinations.reduce(function (r, a) {
      let location;
      if (!r[a.destinationLocationCode]) {
        location = visibleLocations.find((l) => l.destinationLocationCode === a.destinationLocationCode);
      }
      r[a.destinationLocationCode] = r[a.destinationLocationCode] || [location];
      r[a.destinationLocationCode].push(a);
      return r;
    }, Object.create(null));

    const markers = [];
    Object.keys(visibleDestinationsGroupedByLocation).forEach((key) =>
      markers.push(
        <DestinationMapDraggableMarker
          key={visibleDestinationsGroupedByLocation[key][0]._id}
          setMessage={setMessage}
          setFlashTrigger={setFlashTrigger}
          flashTrigger={flashTrigger}
          setDraggingDestinationLocation={setDraggingDestinationLocation}
          draggingDestinationLocation={draggingDestinationLocation}
          setDisplayDestinationMarkersTrigger={setDisplayDestinationMarkersTrigger}
          // 同じ位置コードの納入先一覧を渡す
          destinationsWithSameLocationCode={visibleDestinationsGroupedByLocation[key]}
          // 移動中のマーカーが存在するなら、そのマーカーに初期位置を渡す（移動中に「元に戻す」ボタン用）
          initialPosition={
            initialPositionOfDraggingLocation && visibleDestinationsGroupedByLocation[key][0]._id === draggingDestinationLocation._id
              ? initialPositionOfDraggingLocation
              : new LatLng(visibleDestinationsGroupedByLocation[key][0].destinationLat, visibleDestinationsGroupedByLocation[key][0].destinationLng)
          }
        />,
      ),
    );
    setMarkers(markers);
  }, [destinationLocations, destinationRows, flashTrigger, draggingDestinationLocation, setDisplayDestinationMarkersTrigger, isSmoothMode]);

  // マーカー表示トリガが活性したとき、マーカーを再表示する。（テーブルでデータが更新された時など）
  useEffect(() => {
    if (displayDestinationMarkersTrigger) {
      displayMarkers();
      setDisplayDestinationMarkersTrigger(false);
    }
  }, [displayDestinationMarkersTrigger, setDisplayDestinationMarkersTrigger, destinationRows, displayMarkers]);

  // 地図を動かすとき、マーカーを再表示する
  function OnMoveSetter() {
    useMapEvents({
      moveend: (e) => {
        displayMarkers();
      },
    });
    return null;
  }

  return destinationLocations.length === 0 ? (
    <></>
  ) : !openMapTrigger ? (
    <>
      <IconButton onClick={handleOpen}>
        <MapIcon sx={{ color: 'darkgreen' }} fontSize="medium" />
      </IconButton>
    </>
  ) : (
    <>
      {message && <FlashMessage message={message} flashTrigger={flashTrigger} />}
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openForm}>
        <DestinationForm
          setMessage={setMessage}
          flashTrigger={flashTrigger}
          setFlashTrigger={setFlashTrigger}
          openForm={openForm}
          setOpenForm={setOpenForm}
          currentId={currentId}
          setCurrentId={setCurrentId}
          newLatLng={newLatLng}
          isNewMarkerLatLng={true}
          allData={destinations}
        />
      </Backdrop>
      <div ref={menuRef}>
        <RightClickMenu x={windowX} y={windowY} showMenu={showMenu && (isMap || isMenu)} setOpenForm={setOpenForm} />
      </div>

      <div ref={mapDivRef}>
        <MapContainer
          whenReady={(mapInstance) => {
            mapRef.current = mapInstance;
            displayMarkers();
          }}
          center={position}
          zoom={10}
          doubleClickZoom={false}
          gestureHandling={true}
        >
          <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {markers}
          <MapCloseButton setOpenMapTrigger={setOpenMapTrigger} title={'X'} markerPosition={position} />
          <MapAddressBox setAddress={setAddress} />
          <RightClickLatLngSetter />
          <OnMoveSetter />
          <MapSetView />
          <GoToMarker />
          <SmoothModeSwich isSmoothMode={isSmoothMode} setIsSmoothMode={setIsSmoothMode} />
        </MapContainer>
      </div>
    </>
  );
};

// 画面サイズを取得する
function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default DestinationMap;
