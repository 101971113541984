import React, { useMemo, useState, useEffect, useCallback, useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Button, Typography, Paper, IconButton, Grid, Backdrop, Autocomplete } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FaTrash } from 'react-icons/fa';

import { DestinationMasterColumns } from './Columns/DestinationMasterColumns';
import { DestinationRemarkNames } from './Columns/DestinationRemarkNames';
import { DisplayDestinationMarkersContext } from '../../Maps/DestinationMap/DisplayDestinationMarkersContext';
import ConfirmDialog from '../../Messages/ConfirmDialog';

import { createDestination, createDestinationLocation, deleteDestinationLocation, updateDestination, updateDestinationRemarkNames } from '../../../actions/destinations';
import { checkTypeAndRange, handleDeleteData, setMessageAccordingToDbResult } from '../commonFunctions.js';

import { DESTINATIONS_JP, EDITABLE_COLUMN_NAMES_JP } from '../../../constants/dataTypes';
import { ERROR, SUCCESS_DELETE } from '../../../constants/flashMessages';
import { DB_SUCCESS } from '../../../constants/dbResultCodes';
import { NEW_LOCATION_CODE_STRING } from '../../../constants/specialStrings';

const DestinationForm = ({
  setMessage,
  flashTrigger,
  setFlashTrigger,
  currentId,
  setCurrentId,
  openForm,
  setOpenForm,
  editingColumnNames,
  setEditingColumnNames,
  isNewMarkerLatLng,
  newLatLng,
  allData,
  setRemarkNamesTrigger,
}) => {
  const { setDisplayDestinationMarkersTrigger } = useContext(DisplayDestinationMarkersContext);

  const dispatch = useDispatch();

  const autoCompleteRef = useRef(null);

  const [canLocationBeDeleted, setCanLocationBeDeleted] = useState(false);
  const [locationToDelete, setLocationToDelete] = useState(null);

  // 変更時のテキストデータの更新
  const handleChange = async (e, i, d) => {
    if (d.accessor === 'destinationLocationCode' && !creatingNewDestinationLocationCode) {
      if (e.target.textContent === NEW_LOCATION_CODE_STRING) {
        handleNewLocation();
      } else if (e.target.textContent && destinations?.find((o) => o.destinationLocationCode === e.target.textContent) == null) {
        setCanLocationBeDeleted(true);
        setLocationToDelete(e.target.textContent);
      } else {
        setCanLocationBeDeleted(false);
        setLocationToDelete(null);
      }

      // 納入先位置の特殊な場合
      let location = await destinationLocations?.find((o) => o.destinationLocationCode === e.target.textContent);

      setTextData({
        ...textData,
        [d.accessor]: e.target.value,
        destinationLocationCode: location?.destinationLocationCode,
        destinationLat: location?.destinationLat,
        destinationLng: location?.destinationLng,
      });
    } else {
      // 普通の場合
      setTextData({
        ...textData,
        [d.accessor]: e.target.value,
      });
    }
  };

  //指定車両のテキストボックス更新
  const handleChangePermitedTrucks = async (e, v, d) => {
    console.log(e.target);
    console.log(v);
    const newData = v ?? '';
    setTextData({
      ...textData,
      [d.accessor]: newData,
    });
  }

  // 閉じるボタン
  const handleClose = () => {
    if (setEditingColumnNames != null) setEditingColumnNames(false);
    clear();
    setOpenForm(false);
  };

  // 新規納入先位置の作成用
  const handleNewLocation = () => {
    setCreatingNewDestinationLocationCode(true);
    setTextData({
      ...textData,
      destinationLocationCode: '',
      destinationLat: 0,
      destinationLng: 0,
    });
  };

  // 動作確認ダイアログ（データ削除用）
  const [dialog, setDialog] = useState({
    message: '',
    isOpen: false,
    dataIdentifier: '',
  });
  const confirmDialog = async (action, dataCode) => {
    if (action) {
      const location = destinationLocations.find((o) => o.destinationLocationCode === dataCode);
      const dbResult = await dispatch(deleteDestinationLocation(location._id));
      setDialog({ message: '', isOpen: false, dataIdentifier: '' });
      setMessage(dbResult === DB_SUCCESS ? SUCCESS_DELETE : ERROR);
      setFlashTrigger(flashTrigger + 1);
      clearLocationCodeAutocomplete();
      setLocationToDelete(null);
      setCanLocationBeDeleted(false);
    } else {
      setDialog({ message: '', isOpen: false, dataIdentifier: '' });
    }
  };

  let dataDetails = useMemo(() => [], []);
  // eslint-disable-next-line no-new-object
  let dataObject = useMemo(() => new Object(), []);
  let dataTypeJP = '';
  const [textData, setTextData] = useState(dataObject);

  // クリアボタン
  const clear = useCallback(() => {
    setCanLocationBeDeleted(false);
    setLocationToDelete(null);
    setIsError(new Array(dataDetails.length - 1).fill(false));
    setErrorHelper(new Array(dataDetails.length - 1).fill(''));
    setCurrentId(0);
    setTextData(dataObject);
    setSubmitDisabled(true);
    setCreatingNewDestinationLocationCode(false);
    clearLocationCodeAutocomplete();
  }, [dataObject, setCurrentId, dataDetails.length]);

  function clearLocationCodeAutocomplete() {
    // Autocompleteのクリアボタンをクリックして、フォーカスを外す（もっと良い方法が見つからない）
    if (autoCompleteRef.current && autoCompleteRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator').length > 0) {
      setTimeout(() => {
        autoCompleteRef?.current?.getElementsByClassName('MuiAutocomplete-clearIndicator')[0]?.click();
        document.activeElement.blur();
      }, 100);
    }
  }

  const [creatingNewDestinationLocationCode, setCreatingNewDestinationLocationCode] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  useEffect(() => {
    if (isNewMarkerLatLng) {
      setCreatingNewDestinationLocationCode(newLatLng);
    }
  }, [isNewMarkerLatLng, newLatLng, creatingNewDestinationLocationCode]);

  const row = useSelector((state) => (currentId ? state.data.find((d) => d._id === currentId) : null));
  const destinationRemarkNames = useSelector((state) => state.destinationRemarkNames[0]);
  const destinationLocations = useSelector((state) => state.destinationLocations);
  const destinations = useSelector((state) => state.data);
  const data = !editingColumnNames ? row : destinationRemarkNames;

  // 編集、作成動作
  let createData = () => {};
  let updateData = () => {};
  if (!editingColumnNames) {
    // データを整理する
    function prepareData() {
      let preparedData = { ...textData };
      // 仕掛かり個体数が「空の文字列」の場合、NULLとして登録する
      if (textData.waitingPackageNum === '') {
        preparedData = { ...preparedData, waitingPackageNum: null };
      }
      // 許可車両の入力を綺麗にする（例："AB,CD,,EF,  GH, ," => AB, CD, EF, GH）
      const permittedTrucks = textData.destinationPermittedTruckTypeCodes;
      const split = permittedTrucks.split(',');
      const removeIndexes = [];
      split.forEach((e, i, arr) => {
        const trimmed = e.trim();
        if (trimmed.length > 0) {
          arr[i] = trimmed;
        } else {
          removeIndexes.unshift(i);
        }
      });
      removeIndexes.forEach((i) => {
        split.splice(i, 1);
      });
      let cleanPermittedTrucks = '';
      split.forEach((e, i, arr) => {
        cleanPermittedTrucks += e + (i < arr.length - 1 ? ', ' : '');
      });
      preparedData = { ...preparedData, destinationPermittedTruckTypeCodes: cleanPermittedTrucks };

      return preparedData;
    }

    dataDetails = DestinationMasterColumns;
    dataTypeJP = DESTINATIONS_JP;
    let newLocationCode;
    if (creatingNewDestinationLocationCode) {
      newLocationCode = { destinationLocationCode: textData.destinationLocationCode, destinationLat: textData.destinationLat, destinationLng: textData.destinationLng };
    }
    createData = async () => {
      let dbResult;
      if (creatingNewDestinationLocationCode) {
        dbResult = await dispatch(createDestinationLocation(newLocationCode));
        setCreatingNewDestinationLocationCode(false);
        setDisplayDestinationMarkersTrigger(true);
        if (dbResult !== DB_SUCCESS) return dbResult;
      }
      const preparedData = prepareData();
      dbResult = await dispatch(createDestination(preparedData));
      setDisplayDestinationMarkersTrigger(true);
      return dbResult;
    };
    updateData = async () => {
      let dbResult;
      if (creatingNewDestinationLocationCode) {
        dbResult = await dispatch(createDestinationLocation(newLocationCode));
        setCreatingNewDestinationLocationCode(false);
        setDisplayDestinationMarkersTrigger(true);
        if (dbResult !== DB_SUCCESS) return dbResult;
      }
      const preparedData = prepareData();
      dbResult = await dispatch(updateDestination(currentId, preparedData));
      setDisplayDestinationMarkersTrigger(true);
      return dbResult;
    };
  } else {
    dataDetails = DestinationRemarkNames;
    dataTypeJP = EDITABLE_COLUMN_NAMES_JP;
    updateData = () => dispatch(updateDestinationRemarkNames(currentId, textData));
  }

  // キー＝各カラムのaccessor（名前）、値＝空のデータ、のオブジェクトを用意する。
  for (let i = 0; i < dataDetails.length - 1; i++) {
    if (newLatLng) {
      if (dataDetails[i].accessor === 'destinationLat') {
        dataObject[dataDetails[i].accessor] = newLatLng[0];
      } else if (dataDetails[i].accessor === 'destinationLng') {
        dataObject[dataDetails[i].accessor] = newLatLng[1];
      } else {
        dataObject[dataDetails[i].accessor] = dataDetails[i].type === 'number' ? 0 : '';
      }
    } else {
      dataObject[dataDetails[i].accessor] = dataDetails[i].type === 'number' ? 0 : '';
    }
  }

  const [isError, setIsError] = useState(new Array(dataDetails.length - 1).fill(false));
  const [errorHelper, setErrorHelper] = useState(new Array(dataDetails.length - 1).fill(''));
  // 送信ボタン
  const handleSubmit = async (e) => {
    e.preventDefault();
    const isErrorCopy = JSON.parse(JSON.stringify(isError));
    const errorHelperCopy = JSON.parse(JSON.stringify(errorHelper));

    // データ範囲を確認する
    checkTypeAndRange(dataDetails, textData, allData, currentId, isErrorCopy, setIsError, errorHelperCopy, setErrorHelper, creatingNewDestinationLocationCode, destinationLocations);
    let isOK = true;
    isErrorCopy.every((e) => {
      if (e) {
        isOK = false;
        return false;
      }
      return true;
    });
    if (!isOK) {
      return;
    }

    if (currentId === 0) {
      // 新規データの作成
      const dbResult = await createData();
      setMessageAccordingToDbResult(dbResult, currentId, setMessage, clear);
    } else {
      // データ更新
      const dbResult = await updateData();
      setMessageAccordingToDbResult(dbResult, currentId, setMessage, clear);
      handleClose();
    }

    if (dataDetails[0]?.accessor.includes('remark')) {
      setRemarkNamesTrigger(true);
    }

    // フラッシュメッセージを表示
    setFlashTrigger(flashTrigger + 1);
  };

  // テキストデータオブジェクトへの書き込み
  useEffect(() => {
    if (data) {
      const { _id, ...dataWithout_id } = data;

      let location = destinationLocations.find((o) => o.destinationLocationCode === data.destinationLocationCode);
      setTextData({
        ...dataWithout_id,
        destinationLat: location?.destinationLat,
        destinationLng: location?.destinationLng,
      });
    } else clear();
  }, [data, clear, destinationLocations]);

  // 必須データが入力されているか否かで、送信ボタンの活性状態を更新する。
  useEffect(() => {
    // 全ての必須データが記入されていますか？
    let areAllRequiredFieldsFilledIn = true;

    if (textData) {
      for (let i = 0; i < Object.keys(textData).length; i++) {
        // データ毎に必須かどうか調べる
        let dataDetailsRequired;
        dataDetails.every((d) => {
          if (d.accessor === Object.keys(textData)[i]) {
            dataDetailsRequired = d.required;
            return false;
          }
          return true;
        });

        if ((Object.values(textData)[i] === '' || Object.values(textData)[i] == null) && dataDetailsRequired) {
          // 全ての必須データが記入されていません。
          areAllRequiredFieldsFilledIn = false;
          break;
        }
      }
    }
    // 活性状態を更新する。
    setSubmitDisabled(!areAllRequiredFieldsFilledIn);
  }, [textData, dataDetails]);

  return openForm ? (
    <Paper style={{ maxHeight: '90%', overflow: 'auto' }} elevation={18} sx={{ width: '66%' }}>
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container sx={{ paddingBottom: '20px', paddingLeft: '20px', paddingRight: '20px', paddingTop: '10px' }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={0}>
                <Grid item xs={11}>
                  <Typography sx={{ padding: '10px' }} variant="h6">
                    {currentId ? `${dataTypeJP}を編集する` : `新規${dataTypeJP}を作成する`}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={handleClose}>
                    <CloseIcon sx={{ color: 'red' }} fontSize="large" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {dataDetails.map((d, i, details) => {
                  return i !== details.length - 1 ? (
                    <React.Fragment key={details[i].accessor}>
                      <Grid item xs={d.xs ?? 12}>
                        {d.type !== 'select' ? (
                          <TextField
                            error={isError[i]}
                            helperText={errorHelper[i]}
                            size={'small'}
                            type={d.type === 'time' ? d.type : 'text'} // type number の動きが少しおかしいので、「時間」以外は全てtextにする
                            name={d.accessor}
                            required={d.required}
                            disabled={d.accessor === 'destinationLat' || d.accessor === 'destinationLng' ? !creatingNewDestinationLocationCode : d.disabled}
                            variant="outlined"
                            label={dataTypeJP !== EDITABLE_COLUMN_NAMES_JP ? destinationRemarkNames[d.accessor] ?? d.Header : d.Header}
                            fullWidth
                            onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
                            // 時間を整理する（例）"8:00" -> "08:00"　に直す（そうしないと表示されない）
                            value={d.type === 'time' && textData[d.accessor]?.length === 4 ? '0' + textData[d.accessor] : textData[d.accessor] ?? ''}
                            onChange={(e) => handleChange(e, i, d)}
                          />
                        ) : d.accessor === 'destinationPermittedTruckTypeCodes' ? (
                          //指定車両
                          <Grid item xs={12}>
                              <Autocomplete
                                id="permitted-trucks-label"
                                size={'small'}
                                name={d.accessor}
                                required={d.required}
                                disabled={d.disabled}
                                freeSolo
                                //全納入先の許可車両を取得してから、Set()で重複削除して、配列化して、optionsにセットする
                                options={[...new Set(destinations.map((e) => e.destinationPermittedTruckTypeCodes ?? ''))]}
                                renderInput={(params) => 
                                  <TextField {...params} 
                                    name ="permitted-trucks-text"
                                    label={d.Header}
                                    error={isError[i]}
                                    helperText={errorHelper[i]}
                                    onChange = {(ev) => handleChange(ev, i, d)}
                                  />
                                }
                                defaultValue=''
                                value={textData[d.accessor] ?? ''}
                                onChange = {(ev, val) => {handleChangePermitedTrucks(ev, val, d)}}
                                onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
                              />
                          </Grid>
                        ) : creatingNewDestinationLocationCode ? (
                          <Grid container spacing={1}>
                            <Grid item xs={newLatLng ? 12 : 9.4}>
                              <TextField
                                size="small"
                                name={d.accessor}
                                required={d.required}
                                type={d.type}
                                label={'（新規）' + d.Header}
                                fullWidth
                                error={isError[i]}
                                helperText={errorHelper[i]}
                                onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
                                value={textData[d.accessor] ?? ''}
                                onChange={(e) => handleChange(e, i, d)}
                              />
                            </Grid>
                            <Grid item xs={newLatLng ? 0 : 2.6}>
                              {!newLatLng && (
                                <Button variant="outlined" fullWidth size="small" onClick={() => setCreatingNewDestinationLocationCode(false)}>
                                  <CloseIcon />
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid container spacing={1}>
                            <Grid item xs={canLocationBeDeleted ? 10 : 12}>
                              <Autocomplete
                                ref={autoCompleteRef}
                                id="location-label"
                                fullWidth
                                size={'small'}
                                options={['新規データ作成…'].concat(JSON.parse(JSON.stringify(destinationLocations)).map((e) => e.destinationLocationCode))}
                                renderInput={(params) => <TextField {...params} error={isError[i]} helperText={errorHelper[i]} label={d.Header} value={textData[d.accessor] ?? ''} />}
                                onChange={(e) => handleChange(e, i, d)}
                                defaultValue={currentId !== 0 ? textData[d.accessor] : null}
                              />
                            </Grid>
                            {canLocationBeDeleted && (
                              <Grid item xs={2}>
                                <IconButton style={{ color: 'black' }} size="small" onClick={() => handleDeleteData(locationToDelete, setDialog)}>
                                  <FaTrash fontSize="default" />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        )}
                      </Grid>
                    </React.Fragment>
                  ) : null;
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ backgroundColor: '#ffffff', zIndex: '2', paddingBottom: '1rem', paddingLeft: '1rem', paddingRight: '1rem', position: 'sticky', bottom: '0rem' }}>
          <Grid item xs={6}>
            <Button className={'buttonSubmit'} disabled={submitDisabled} variant="contained" color="primary" size="large" type="submit" fullWidth>
              {currentId ? '編集' : '新規作成'}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" color="secondary" onClick={handleClose} fullWidth>
              キャンセル
            </Button>
          </Grid>
        </Grid>
      </form>

      {dialog.isOpen && (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <ConfirmDialog onDialog={confirmDialog} message={dialog.message} dataIdentifier={dialog.dataIdentifier} />
        </Backdrop>
      )}
    </Paper>
  ) : (
    ''
  );
};

export default DestinationForm;
