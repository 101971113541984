import React, { useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { SUCCESS_CREATE, SUCCESS_EDIT, SUCCESS_DELETE, ERROR, SUCCESS_LOGIN, SUCCESS_PASSWORD_CREATE, ERROR_CONFLICT } from '../../constants/flashMessages';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FlashMessage = ({ message, flashTrigger }) => {
  const [open, setOpen] = React.useState(true);
  const vertical = 'top';
  const horizontal = 'right';

  let severity;
  switch (message) {
    case ERROR:
    case ERROR_CONFLICT:
      severity = 'error';
      break;
    case SUCCESS_CREATE:
    case SUCCESS_EDIT:
    case SUCCESS_DELETE:
    case SUCCESS_PASSWORD_CREATE:
    case SUCCESS_LOGIN:
    default:
      severity = 'success';
      break;
  }

  useEffect(() => {
    if (flashTrigger) {
      setOpen(false);
      setTimeout(() => {
        setOpen(true);
      }, 100);
    }
  }, [flashTrigger]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default FlashMessage;
