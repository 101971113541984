import styled from 'styled-components';

export const DivStyles = styled.div`
  display: inline-block;
  width: 100%;
  max-width: 100%;

  .table {
    border: 1px solid #ddd;
    max-width: 100%;

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th {
      overflow-x: scroll;
      scroll-behavior: smooth;
      white-space: nowrap;
      &::-webkit-scrollbar {
        background: transparent;
        -webkit-appearance: none;
        width: 0;
        height: 0;
      }
    }

    .th,
    .td {
      padding: 5px;

      :last-child {
        border-right: 0;
      }
    }

    .th {
      background-color: #044594;
      color: #fff;
      font-weight: bold;
      white-space: 'pre';
    }

    .td {
      white-space: nowrap;
      overflow-x: auto;
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      background-color: #fff;
    }

    &.sticky {
      .header {
        position: sticky;
        z-index: 4;
        top: 0;
        box-shadow: 0px 3px 3px #ccc;
        background-color: #044594;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc;
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 2.5px 3px #ccc;
      }
    }
  }
`;
