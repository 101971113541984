import { Component } from 'react';
import { connect } from 'react-redux';
import { verifyWithId } from '../../actions/auth.js';

class VerifyToken extends Component {
  async componentDidMount() {
    const { dispatch, verifyWithId, token, setIsValidToken, setHasTokenBeenChecked, getPackingResponse, resultId } = this.props;
    await dispatch(verifyWithId({ token: token, id: resultId }));
    const isValidToken = JSON.parse(localStorage.getItem('packingToken')).isValidToken;
    setHasTokenBeenChecked(true);
    setIsValidToken(isValidToken);
    if (isValidToken) {
      await dispatch(getPackingResponse(resultId));
    }
  }
  render() {
    return <div></div>;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    verifyWithId,
  };
}

export default connect(mapDispatchToProps)(VerifyToken);
