import axios from 'axios';

const API = axios.create({ baseURL: process.env.REACT_APP_API_URL });

API.interceptors.request.use((req) => {
  if (localStorage.getItem('profile')) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
  } else if (localStorage.getItem('packingToken')) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('packingToken')).token}`;
  }

  return req;
});

// AUTH
export const signIn = (formData) => API.post('/user/signin', formData);
export const signUp = (formData) => API.post('/user/signup', formData);
export const verify = (token) => API.post('/user/verify', token);
export const verifyWithId = (info) => API.post('/user/verifywithid', info);

// TRUCK
export const fetchTrucks = () => API.get(`/trucks`);
export const createTruck = (newTruck) => API.post('/trucks', newTruck);
export const updateTruck = (id, updatedTruck) => API.patch(`/trucks/${id}`, updatedTruck);
export const deleteTruck = (id) => API.delete(`/trucks/${id}`);

// PACKAGE
export const fetchPackages = () => API.get(`/packages`);
export const createPackage = (newPackage) => API.post('/packages', newPackage);
export const updatePackage = (id, updatedPackage) => API.patch(`/packages/${id}`, updatedPackage);
export const deletePackage = (id) => API.delete(`/packages/${id}`);

// DESTINATION
export const fetchGeocoding = (address) => API.get(`/destinations/geocode/${address}`);
export const fetchDestinations = () => API.get(`/destinations`);
export const fetchDestinationLocations = () => API.get(`/destinations/locations`);
export const fetchDestinationRemarkNames = () => API.get(`/destinations/remarknames`);
export const createDestination = (newDestination) => API.post('/destinations', newDestination);
export const createDestinationLocation = (newDestinationLocation) => API.post('/destinations/locations', newDestinationLocation);
export const updateDestination = (id, updatedDestination) => API.patch(`/destinations/${id}`, updatedDestination);
export const updateDestinationLocation = (id, updatedDestinationLocation) => API.patch(`/destinations/locations/${id}`, updatedDestinationLocation);
export const updateDestinationRemarkNames = (id, updatedDestinationRemarkNames) => API.patch(`/destinations/remarknames/${id}`, updatedDestinationRemarkNames);
export const deleteDestination = (id) => API.delete(`/destinations/${id}`);
export const deleteDestinationLocation = (id) => API.delete(`/destinations/locations/${id}`);

// DESTINATION(CSVインポート)
export const uploadDestinationFile = (data) => API.post('/destinations/uploadFile', data, { "content-type": "multipart/form-data" })

// DEPOT
export const fetchDepots = () => API.get(`/depots`);
export const createDepot = (newDepot) => API.post('/depots', newDepot);
export const updateDepot = (id, updatedDepot) => API.patch(`/depots/${id}`, updatedDepot);
export const deleteDepot = (id) => API.delete(`/depots/${id}`);

// PICK UP POINT
export const fetchPickUpPoints = () => API.get(`/pickUpPoints`);
export const createPickUpPoint = (newPickUpPoint) => API.post('/pickUpPoints', newPickUpPoint);
export const updatePickUpPoint = (id, updatedPickUpPoint) => API.patch(`/pickUpPoints/${id}`, updatedPickUpPoint);
export const deletePickUpPoint = (id) => API.delete(`/pickUpPoints/${id}`);

// PACKING RESPONSE
export const fetchPackingResponse = (id) => API.get(`/packingResponse/${id}`);
export const fetchPackingResponses = () => API.get(`/packingResponse`);
