import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';

export const SidebarData = [
  {
    title: 'デポマスタ',
    path: '/depot',
    icon: <AiIcons.AiFillHome />,
    className: 'navText',
  },
  {
    title: '倉庫マスタ',
    path: '/pickUpPoint',
    icon: <FaIcons.FaWarehouse />,
    className: 'navText',
  },
  {
    title: '納入先マスタ',
    path: '/destination',
    icon: <FaIcons.FaMapMarkerAlt />,
    className: 'navText',
  },
  {
    title: 'ラックサイズマスタ',
    path: '/package',
    icon: <FaIcons.FaBoxes />,
    className: 'navText',
  },
  {
    title: '車両マスタ',
    path: '/truck',
    icon: <FaIcons.FaTruck />,
    className: 'navText',
  },
  {
    title: 'ログアウト',
    path: '/auth',
    icon: <AiIcons.AiOutlineLogout />,
    className: 'navText',
  },
];
