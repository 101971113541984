export const TRUCKS = 'TRUCKS';
export const PACKAGES = 'PACKAGES';
export const DEPOTS = 'DEPOTS';
export const PICK_UP_POINTS = 'PICK_UP_POINTS';

export const TRUCKS_JP = 'トラック';
export const PACKAGES_JP = 'ラック';
export const DEPOTS_JP = 'デポ';
export const DESTINATIONS_JP = '納入先';
export const PICK_UP_POINTS_JP = '倉庫';

export const EDITABLE_COLUMN_NAMES_JP = 'カラム名';
