import { Component } from 'react';
import { connect } from 'react-redux';
import { getDestinationRemarkNames } from '../../../actions/destinations';

class LoadRemarkNames extends Component {
  async componentDidMount() {
    const { dispatch, setRemarkNamesTrigger } = this.props;
    await dispatch(getDestinationRemarkNames());
    setRemarkNamesTrigger(true);
  }
  render() {
    return <div></div>;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getDestinationRemarkNames,
  };
}

export default connect(mapDispatchToProps)(LoadRemarkNames);
