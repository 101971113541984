import { highlightableCell } from '../../commonFunctions.js';

export const TruckMasterColumns = [
  {
    Header: 'トラックコード',
    accessor: 'truckTypeCode',
    accessorForCsv: 'truckTypeCode',
    type: 'text',
    required: true,
    Cell: highlightableCell,
    percentWidth: '15.5%',
  },
  {
    Header: 'トラック名称',
    accessor: 'truckTypeName',
    accessorForCsv: 'truckTypeName',
    type: 'text',
    required: true,
    Cell: highlightableCell,
    percentWidth: '21%',
  },
  {
    Header: '長さ（mm）',
    accessor: 'truckTypeLength',
    accessorForCsv: 'truckTypeLength',
    disableGlobalFilter: true,
    type: 'number',
    required: true,
    percentWidth: '12.5%',
  },
  {
    Header: '幅（mm）',
    accessor: 'truckTypeWidth',
    accessorForCsv: 'truckTypeWidth',
    disableGlobalFilter: true,
    type: 'number',
    required: true,
    percentWidth: '12.5%',
  },
  {
    Header: '高さ（mm）',
    accessor: 'truckTypeHeight',
    accessorForCsv: 'truckTypeHeight',
    disableGlobalFilter: true,
    type: 'number',
    required: true,
    percentWidth: '12.5%',
  },
  {
    Header: '最大積載量（kg）',
    accessor: 'truckTypeMaxWeight',
    accessorForCsv: 'truckTypeMaxWeight',
    disableGlobalFilter: true,
    type: 'number',
    required: true,
    percentWidth: '18%',
  },
  {
    Header: '.',
    accessor: '_id',
    disableGlobalFilter: true,
    percentWidth: '8%',
  },
];
