import React, { useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import PickUpPointMapDraggableMarker from './PickUpPointMapDraggableMarker';
import FlashMessage from '../../Messages/FlashMessage';

let position = [36.7454436, 137.2276794];

const PickUpPointMap = ({ pickUpPoints }) => {
  // Only allow one draggable marker at a time
  const [isDragging, setIsDragging] = useState(false);

  // Flash message things
  const [message, setMessage] = useState('');
  const [flashTrigger, setFlashTrigger] = useState(50);

  if (pickUpPoints.length > 0) {
    position = [pickUpPoints[0].pickUpPointLat, pickUpPoints[0].pickUpPointLng];
  } else {
    return;
  }

  return pickUpPoints[0].pickUpPointLat === undefined ? (
    <></>
  ) : (
    <>
      {message && <FlashMessage message={message} flashTrigger={flashTrigger} />}
      <MapContainer center={position} zoom={10} scrollWheelZoom={true} gestureHandling={true}>
        <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {pickUpPoints.map((pickUpPoint) => {
          return (
            <PickUpPointMapDraggableMarker
              key={pickUpPoint._id}
              setMessage={setMessage}
              setFlashTrigger={setFlashTrigger}
              flashTrigger={flashTrigger}
              setIsDragging={setIsDragging}
              isDragging={isDragging}
              pickUpPoint={pickUpPoint}
            />
          );
        })}
      </MapContainer>
    </>
  );
};

export default PickUpPointMap;
