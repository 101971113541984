import { highlightableCell } from '../../commonFunctions.js';

export const PackageMasterColumns = [
  {
    Header: 'ラックサイズコード',
    accessor: 'packageSizeCode',
    accessorForCsv: 'packageSizeCode',
    type: 'text',
    required: true,
    Cell: highlightableCell,
    percentWidth: '17.5%',
  },
  {
    Header: 'ラックサイズ名称',
    accessor: 'packageSizeName',
    accessorForCsv: 'packageSizeName',
    type: 'text',
    required: true,
    Cell: highlightableCell,
    percentWidth: '21%',
  },
  {
    Header: '長さ（mm）',
    accessor: 'packageLength',
    accessorForCsv: 'packageLength',
    disableGlobalFilter: true,
    type: 'number',
    required: true,
    percentWidth: '12%',
  },
  {
    Header: '幅（mm）',
    accessor: 'packageWidth',
    accessorForCsv: 'packageWidth',
    disableGlobalFilter: true,
    type: 'number',
    required: true,
    percentWidth: '12%',
  },
  {
    Header: '高さ（mm）',
    accessor: 'packageHeight',
    accessorForCsv: 'packageHeight',
    disableGlobalFilter: true,
    type: 'number',
    required: true,
    percentWidth: '12%',
  },
  {
    Header: 'ラック段積みタイプ',
    accessor: 'packageStackingType',
    accessorForCsv: 'packageStackingType',
    disableGlobalFilter: true,
    type: 'text',
    required: true,
    percentWidth: '17.5%',
  },
  {
    Header: '.',
    accessor: '_id',
    disableGlobalFilter: true,
    percentWidth: '8%',
  },
];
