import { Button } from '@mui/material';
import xMark from '../../../images/xMark.png';

const RightClickMenu = ({ x, y, showMenu, setOpenForm }) => {
  const xMarkDimensions = 50;
  const style = () => {
    return {
      margin: '8px',
      padding: '7px',
      height: 50,
      width: 150,
      borderRadius: 10,
      border: 'solid 1px',
      backgroundColor: '#FFF',
      flexDirection: 'column',
      top: y,
      left: x,
      zIndex: 999,
      position: 'absolute',
      display: showMenu ? 'flex' : 'none',
    };
  };
  const xMarkStyle = () => {
    return {
      height: xMarkDimensions,
      width: xMarkDimensions,
      top: y - xMarkDimensions / 2,
      left: x - xMarkDimensions / 2,
      zIndex: 1000,
      position: 'absolute',
      display: showMenu ? 'flex' : 'none',
    };
  };

  const handleClick = () => {
    setOpenForm(true);
  };

  return (
    <>
      <div style={xMarkStyle()}>
        <img src={xMark} alt="icon" />
      </div>

      <div style={style()}>
        <Button sx={{ marginBottom: '10px' }} onClick={handleClick}>
          新規納入先作成
        </Button>
      </div>
    </>
  );
};

export default RightClickMenu;
