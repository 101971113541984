import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow, { tableRowClasses } from '@mui/material/TableRow';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#044594',
    color: theme.palette.common.white,
    fontWeight: 'bold',
    whiteSpace: 'pre',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // Hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  [`&.${tableRowClasses.selected}`]: {
    backgroundColor: "#ff00ff" ,
  },
  [`&.${tableRowClasses.selected}:hover`]: {
    backgroundColor: "#ff00ff" ,
  },
}));

export const StyledTableHeaderInBodyCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: '#044594',
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
}));
