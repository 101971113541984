import { highlightableCell, shortenLatLng } from '../../commonFunctions.js';

export const PickUpPointMasterColumns = [
  {
    Header: '倉庫コード',
    accessor: 'pickUpPointCode',
    accessorForCsv: 'pickUpPointCode',
    type: 'text',
    required: true,
    Cell: highlightableCell,
    percentWidth: '18%',
  },
  {
    Header: '倉庫名称',
    accessor: 'pickUpPointName',
    accessorForCsv: 'pickUpPointName',
    type: 'text',
    required: true,
    Cell: highlightableCell,
    percentWidth: '36%',
  },
  {
    Header: '倉庫緯度',
    accessor: 'pickUpPointLat',
    accessorForCsv: 'pickUpPointLat',
    disableGlobalFilter: true,
    type: 'number',
    required: true,
    Cell: ({ value }) => shortenLatLng(value),
    percentWidth: '17%',
  },
  {
    Header: '倉庫経度',
    accessor: 'pickUpPointLng',
    accessorForCsv: 'pickUpPointLng',
    disableGlobalFilter: true,
    type: 'number',
    required: true,
    Cell: ({ value }) => shortenLatLng(value),
    percentWidth: '17%',
  },
  {
    Header: '.',
    accessor: '_id',
    disableGlobalFilter: true,
    percentWidth: '12%',
  },
];
