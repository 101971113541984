import React from 'react';
import { Button, Typography, Paper, Grid } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

const ConfirmDialog = ({ message, onDialog, dataIdentifier, setDialog, setMessage, setFlashTrigger, flashTrigger, deleteData }) => {
  function lineBreak(string) {
    return string.split('<br/>');
  }

  return (
    <Paper className={'paper'} elevation={18} sx={{ width: '25%' }}>
      <Grid container direction="column" alignItems="center" sx={{ paddingTop: '25px' }}>
        <Grid item xs={12} sx={{ margin: '5px' }}>
          <WarningIcon fontSize="large" color="warning" />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ padding: '20px' }}>
        <Grid item xs={12} sx={{ paddingBottom: '15px' }}>
          {lineBreak(message).map((line, i) => {
            return (
              <Typography align="center" key={i}>
                {line}
              </Typography>
            );
          })}
        </Grid>
        <Grid item xs={6}>
          <Button onClick={() => onDialog(true, dataIdentifier, setDialog, setMessage, setFlashTrigger, flashTrigger, deleteData)} variant="outlined" fullWidth>
            はい
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button onClick={() => onDialog(false, null, setDialog, setMessage, setFlashTrigger, flashTrigger, deleteData)} variant="contained" fullWidth>
            いいえ
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ConfirmDialog;
