import { Component } from 'react';
import { connect } from 'react-redux';
import { getDestinations, getDestinationLocations } from '../../../actions/destinations';

class LoadDestinations extends Component {
  async componentDidMount() {
    const { dispatch, getDestinations, getDestinationLocations, setDestinationsLoaded } = this.props;
    await dispatch(getDestinations());
    await dispatch(getDestinationLocations());
    setDestinationsLoaded(true);
  }
  render() {
    return <div></div>;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getDestinations,
    getDestinationLocations,
  };
}

export default connect(mapDispatchToProps)(LoadDestinations);
