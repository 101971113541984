import { FETCH_ALL_REMARK_NAMES, UPDATE_REMARK_NAMES } from '../constants/actionTypes';

const destinationRemarkNamesReducer = (destinationRemarkNames = [], action) => {
  switch (action.type) {
    case FETCH_ALL_REMARK_NAMES:
      return action.payload;
    case UPDATE_REMARK_NAMES:
      return destinationRemarkNames.map((destinationRemarkName) => (destinationRemarkName._id === action.payload._id ? action.payload : destinationRemarkName));
    default:
      return destinationRemarkNames;
  }
};

export default destinationRemarkNamesReducer;
