import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Paper, Grid, Container } from '@mui/material';
import * as AiIcons from 'react-icons/ai';
import { useNavigate, useLocation } from 'react-router-dom';
import * as actionType from '../../constants/actionTypes';
import { SidebarData } from '../../components/Navbar/SidebarData';

const Home = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));

  const navigate = useNavigate();
  const routeChange = (route) => {
    if (route === '/auth' && user) logout();
    navigate(route);
  };
  const location = useLocation();
  const dispatch = useDispatch();
  const logout = () => {
    dispatch({ type: actionType.LOGOUT });

    navigate('/auth');

    setUser(null);
  };

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('profile')));
  }, [location]);

  return (
    <Container component="main" maxWidth="xs">
      <Paper className={'paper'} elevation={6} sx={{ marginTop: '25px', padding: '20px' }}>
        <Grid container alignItems="center" spacing={2}>
          {user?.result ? (
            SidebarData.map((item, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <Button onClick={() => routeChange(item.path)} fullWidth variant="contained" color="primary">
                    {item.icon}
                    <span>{item.title}</span>
                  </Button>
                </Grid>
              );
            })
          ) : (
            <Grid item xs={12}>
              <Button onClick={() => routeChange('/auth')} fullWidth variant="contained" color="primary">
                <AiIcons.AiOutlineLogin />
                <span>ログイン</span>
              </Button>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Container>
  );
};

export default Home;
