import React from 'react';
import { TextField, Button, Typography, Paper, IconButton, Grid, Backdrop, Autocomplete } from '@mui/material';
import DestinationFileUploadButton from '../../Maps/DestinationMap/DestinationFileUploadButton';
import CloseIcon from '@mui/icons-material/Close';

const DestinationFileUploadForm = ({
  openForm,
  setOpenForm,
}) => {

  // 閉じるボタン
  const handleClose = () => {
    setOpenForm(false);
  };

  return openForm ? (
    <Paper style={{ maxHeight: '90%', overflow: 'auto' }} elevation={18} sx={{ width: '50%' }}>
      <Grid item xs={12}>
        <Grid container spacing={0}>
          <Grid item xs={11}>
            <Typography sx={{ padding: '10px' }} variant="h6">
                {`CSVインポート`}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={handleClose}>
              <CloseIcon sx={{ color: 'red' }} fontSize="large" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={11}>
            <ul>
              <li>カンマ区切り、UTF-8で保存されたcsvファイルを取り込むことができます。</li>
              <li>1行目はヘッダ行のため、読み込みをスキップします。</li>
              <li>1列目(A列)を納入先コードとします。納入先コードがマスタ登録済みかどうかで、データの扱いが変わります。
                <ul>
                  <li>既にマスタに登録済の納入先コードのデータ(上書き更新)
                    <ul>
                      <li>6~39列目(F~AM列)をそれぞれ指定時刻(から),指定時刻(まで),許可車両,仕掛個体数,備考1~備考30として取り込みます。</li>
                      <li>2~5列目は無視します。</li>
                    </ul>
                  </li>
                  <li>マスタ未登録の納入先コードのデータ(新規登録)
                    <ul>
                      <li>2列目(B列)を納入先名称,3列目(C列)を納入先位置コードとして取り込みます。</li>
                      <li>未登録の納入先位置コードを指定した場合は,4,5列目(D,E列)をそれぞれ緯度と経度として取り込みます。</li>
                      <li>登録済みの納入先位置コードを指定した場合は,4,5列目(D,E列)は無視します。</li>
                      <li>6~39列目の項目は上書き更新の場合と同様です。</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>csvファイルに存在しない納入先は何もしません。(削除もされません)</li>
            </ul>
          </Grid>
        </Grid>
      </Grid>
      <DestinationFileUploadButton />
    </Paper>
  ):(
    ''
  );
};

export default DestinationFileUploadForm;
