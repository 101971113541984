import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Paper } from '@mui/material';

import PickUpPointMap from '../../components/Maps/PickUpPointMap/PickUpPointMap';
import { getPickUpPoints } from '../../actions/pickUpPoints';
import DataTable from '../../components/Table/NotDestination/DataTable';
import { PICK_UP_POINTS } from '../../constants/dataTypes';

const PickUpPoints = () => {
  const [currentId] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPickUpPoints());
  }, [currentId, dispatch]);

  const pickUpPoints = useSelector((state) => state.data);

  return (
    <Grid container spacing={3} sx={{ paddingTop: '20px' }}>
      <Grid item xs={6} className="table-wrapper">
        <DataTable data={pickUpPoints} dataType={PICK_UP_POINTS} />
      </Grid>
      <Grid item xs={6}>
        <Paper>
          <PickUpPointMap pickUpPoints={pickUpPoints} />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PickUpPoints;
