import { FETCH_ALL, FETCH_ALL_REMARK_NAMES, CREATE, CREATE_LOCATION, UPDATE, UPDATE_LOCATION, UPDATE_REMARK_NAMES, DELETE, DELETE_LOCATION, FETCH_ALL_LOCATIONS } from '../constants/actionTypes';
import * as api from '../api/index.js';
import { DB_ERROR_ANY, DB_ERROR_CONFLICT, DB_SUCCESS } from '../constants/dbResultCodes';

export const getDestinations = () => async (dispatch) => {
  try {
    const { data } = await api.fetchDestinations();

    dispatch({ type: FETCH_ALL, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const getDestinationLocations = () => async (dispatch) => {
  try {
    const { data } = await api.fetchDestinationLocations();

    dispatch({ type: FETCH_ALL_LOCATIONS, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const getDestinationRemarkNames = () => async (dispatch) => {
  try {
    const { data } = await api.fetchDestinationRemarkNames();

    dispatch({ type: FETCH_ALL_REMARK_NAMES, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const createDestination = (destination) => async (dispatch) => {
  try {
    const { data } = await api.createDestination(destination);

    dispatch({ type: CREATE, payload: data });
    return DB_SUCCESS;
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message?.includes('duplicate key value violates unique constraint')) {
      return DB_ERROR_CONFLICT;
    }
    return DB_ERROR_ANY;
  }
};

export const createDestinationLocation = (destinationLocation) => async (dispatch) => {
  try {
    const { data } = await api.createDestinationLocation(destinationLocation);

    dispatch({ type: CREATE_LOCATION, payload: data });
    return DB_SUCCESS;
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message?.includes('duplicate key value violates unique constraint')) {
      return DB_ERROR_CONFLICT;
    }
    return DB_ERROR_ANY;
  }
};

export const updateDestination = (id, destination) => async (dispatch) => {
  try {
    const { data } = await api.updateDestination(id, destination);

    dispatch({ type: UPDATE, payload: data });
    return DB_SUCCESS;
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message?.includes('duplicate key value violates unique constraint')) {
      return DB_ERROR_CONFLICT;
    }
    return DB_ERROR_ANY;
  }
};

export const updateDestinationLocation = (id, destinationLocation) => async (dispatch) => {
  try {
    const { data } = await api.updateDestinationLocation(id, destinationLocation);

    dispatch({ type: UPDATE_LOCATION, payload: data });
    return DB_SUCCESS;
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message?.includes('duplicate key value violates unique constraint')) {
      return DB_ERROR_CONFLICT;
    }
    return DB_ERROR_ANY;
  }
};

export const updateDestinationRemarkNames = (id, destinationRemarkName) => async (dispatch) => {
  try {
    const { data } = await api.updateDestinationRemarkNames(id, destinationRemarkName);

    dispatch({ type: UPDATE_REMARK_NAMES, payload: data });
    return DB_SUCCESS;
  } catch (error) {
    console.log(error);
    return DB_ERROR_ANY;
  }
};

export const deleteDestination = (id) => async (dispatch) => {
  try {
    await await api.deleteDestination(id);

    dispatch({ type: DELETE, payload: id });
    return DB_SUCCESS;
  } catch (error) {
    console.log(error);
    return DB_ERROR_ANY;
  }
};

export const deleteDestinationLocation = (id) => async (dispatch) => {
  try {
    await await api.deleteDestinationLocation(id);

    dispatch({ type: DELETE_LOCATION, payload: id });
    return DB_SUCCESS;
  } catch (error) {
    console.log(error);
    return DB_ERROR_ANY;
  }
};
