import React  from 'react';
import { Paper, Table, TableHead, TableBody, TableContainer } from '@mui/material';
import { StyledTableCell, StyledTableRow } from './styles.js';

const PackageDetails = ({ packingResponse,  setSelectedPackageRow, selectedPackageRow}) => {
  const packingList = packingResponse?.packingList;

  return (
    <TableContainer component={Paper} sx={{ marginBottom: '40px' }}>
      <Table>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>ラックNo.</StyledTableCell>
            <StyledTableCell>納入先名称</StyledTableCell>
            <StyledTableCell>ラックサイズ</StyledTableCell>
            <StyledTableCell>重量（kg）</StyledTableCell>
            <StyledTableCell>庫内位置（行, 列, 段）</StyledTableCell>
            <StyledTableCell>サイズ（W, L, H）</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {packingList?.map((p, i) => {
            const isSelected = (selectedPackageRow === i );
            const toggleSelectedPackageRow = function(j){
              if(selectedPackageRow === j){
                setSelectedPackageRow(null);
              }else{
                setSelectedPackageRow(j);
              }
            };
            return (
              <StyledTableRow key={'package' + i} onClick={()=>toggleSelectedPackageRow(i)} selected={isSelected}>
                <StyledTableCell >{p.packageNo ?? ' - '}</StyledTableCell>
                <StyledTableCell>{p.destinationName ?? ' - '}</StyledTableCell>
                <StyledTableCell>{p.packageSizeName ?? ' - '}</StyledTableCell>
                <StyledTableCell>{p.packageWeight ?? ' - '}</StyledTableCell>
                <StyledTableCell style={p.row === -1 ? { color: 'red' } : {}}>{`(${p.row}, ${p.col}, ${p.lvl})`}</StyledTableCell>
                <StyledTableCell>{p.packageWidth && p.packageLength && p.packageHeight ? `(${p.packageWidth}, ${p.packageLength}, ${p.packageHeight})` : ' - '}</StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PackageDetails;
