import React from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Sky, Edges, PerspectiveCamera } from '@react-three/drei';
import { Physics, useBox, usePlane } from '@react-three/cannon';
import { Paper, Typography } from '@mui/material';

let truckLength = 0;
let truckHeight = 0;
let truckWidth = 0;

// トラックのコンテナ
const TruckContainer = ({ length, height, width }) => {
  const [ref] = useBox(() => ({ position: [0, 0, 0] }));
  return length === 0 && height === 0 && width === 0 ? (
    <></>
  ) : (
    <mesh ref={ref}>
      <boxGeometry args={[length + 0.01, height + 0.01, width + 0.01]} />
      <meshStandardMaterial color="black" transparent opacity={0} wireframe />
      <Edges />
    </mesh>
  );
};

// ラック
const Package = ({ length, height, width, posL, posH, posW, isUnplaced = false, isSelected }) => {
  // トラックの一角を「0, 0, 0」座標として設定する（調整しないと「0, 0, 0」座標はトラックの中心）
  const baseLengthAxis = 0 - truckLength / 2000 + length / 2;
  const baseHeightAxis = 0 - truckHeight / 2000 + height / 2;
  const baseWidthAxis = 0 + truckWidth / 2000 - width / 2;

  const [ref] = useBox(() => ({ position: [baseLengthAxis + posL / 1000, baseHeightAxis + posH / 1000, baseWidthAxis - posW / 1000] }));
  return (
    <mesh ref={ref}>
      <boxGeometry args={[length, height, width]} />
      <meshStandardMaterial color={isSelected ? '#ff00ff' : (isUnplaced ? 'red' : 'yellow')} opacity={0.7} transparent />
    </mesh>
  );
};

// 灰色の平面
const Plane = () => {
  usePlane(() => ({ rotation: [-Math.PI / 2, 0, 0] }));
  return (
    <mesh position={[0, -10, 0]} rotation={[-Math.PI / 2, 0, 0]}>
      <boxBufferGeometry args={[1000, 1000, 0.01]} />
      <meshLambertMaterial color="lightgrey" />
    </mesh>
  );
};

const TruckDisplay = ({ packingResponse, overlappingHeight, selectedPackageRow}) => {
  const packingList = packingResponse?.packingList;

  truckLength = packingResponse?.truckTypeLength ?? 0;
  truckHeight = packingResponse?.truckTypeHeight ?? 0;
  truckWidth = packingResponse?.truckTypeWidth ?? 0;

  // ラック一覧を用意（3D表示用）
  const packages = [];
  const unplacedPackages = [];
  let prevRow = -1,
    prevCol = -1,
    prevLvl = -1;
  packingList?.forEach((p, i) => {
    const newRow = p?.row,
      newCol = p?.col,
      newLvl = p?.lvl;
      p.idx = i;
    let newRowWasPushed = false,
      newColWasPushed = false;
    if (prevRow !== newRow) {
      packages.push([]);
      newRowWasPushed = true;
    }
    if (prevCol !== newCol || newRowWasPushed) {
      packages[newRow - 1]?.push([]);
      newColWasPushed = true;
    }
    if (prevLvl !== newLvl || newColWasPushed) {
      packages[newRow - 1][newCol - 1]?.push(p);
    }
    if (newRow === -1 || newCol === -1 || newLvl === -1) unplacedPackages.push(p);
    prevRow = newRow;
    prevCol = newCol;
    prevLvl = newLvl;
  });

  // ラック間に必要な余分な間隔を計算する
  const spaceBetweenRows = packingResponse?.backMargin / (packages.length + 1);
  const spaceBetweenColumns = [];
  const rowLengths = [];
  const accumulatedRowLengths = [];
  accumulatedRowLengths[0] = 0;
  packages?.forEach((row, i) => {
    rowLengths[i] = row[0][0].packageLength;
    accumulatedRowLengths.push(accumulatedRowLengths[i] + row[0][0].packageLength);
    let accumulatedColumnWidths = 0;
    row.forEach((column) => {
      accumulatedColumnWidths += column[0].packageWidth;
    });
    spaceBetweenColumns[i] = (truckWidth - accumulatedColumnWidths) / (row.length + 1);
  });

  let posL = spaceBetweenRows;
  let previousUnplacedPosW = 0;

  return (
    <Paper sx={{ position: 'sticky', top: '1rem' }}>
      <Typography
        sx={{ padding: '5px', paddingLeft: '10px' }}
        variant="h7"
        style={packingResponse?.status !== 'Success' ? { color: 'red' } : {}}
      >{`W：${truckWidth}　L：${truckLength}　H：${truckHeight}`}</Typography>
      <div style={{ height: '70vh' }}>
        <Canvas>
          <PerspectiveCamera makeDefault fov={75} position={[7, 2, -3]} />
          <OrbitControls minZoom={1} />
          <ambientLight intensity={0.3} />
          <Sky sunPosition={[2.2, 1, 2.2]} />
          <spotLight position={[13, 5, 13]} angle={3} />
          <Physics>
            <Plane />
            {packages?.map((row, i) => {
              let posW = spaceBetweenColumns[i];
              return row.map((column, j, row) => {
                let posH = 0;
                let lengthOfSpaceBeneath = rowLengths[i];
                let widthOfPackageBeneath;
                return column.map((p, k, column) => {
                  if (lengthOfSpaceBeneath > p.packageLength) {
                    posL += (lengthOfSpaceBeneath - p.packageLength) / 2;
                    lengthOfSpaceBeneath = p.packageLength;
                  }
                  if (k === 0) {
                    // 最下ラックの場合
                    widthOfPackageBeneath = p.packageWidth;
                  } else if (k >= 1) {
                    // 最下ラックでない場合
                    if (p.packageWidth < widthOfPackageBeneath) {
                      posW += (widthOfPackageBeneath - p.packageWidth) / 2;
                      widthOfPackageBeneath = p.packageWidth;
                    }
                  }

                  const thisPosH = posH;
                  const thisPosW = posW;
                  const thisPosL = posL;

                  posH += p.packageHeight - overlappingHeight;
                  if (k + 1 === column.length) {
                    // 本列の最終ラックとなる場合
                    posL = accumulatedRowLengths[i] + (i + 1) * spaceBetweenRows;

                    let accumulatedColumnWidths = spaceBetweenColumns[i];
                    for (let a = 0; a <= j; a++) {
                      accumulatedColumnWidths += row[a][0].packageWidth + spaceBetweenColumns[i];
                    }
                    posW = accumulatedColumnWidths;

                    if (j + 1 === row.length) {
                      // 本行の最終列となる場合
                      posL = accumulatedRowLengths[i + 1] + (i + 2) * spaceBetweenRows;
                    }
                  }
                  const isSelected = (p.idx === selectedPackageRow);
                  return <Package length={p.packageLength / 1000} height={p.packageHeight / 1000} width={p.packageWidth / 1000} key={k} posL={thisPosL} posH={thisPosH} posW={thisPosW} isSelected={isSelected}/>;
                });
              });
            })}
            {unplacedPackages?.map((p, i, packages) => {
              previousUnplacedPosW += i !== 0 ? packages[i - 1].packageWidth + 100 : 0;
              const isSelected = (p.idx === selectedPackageRow);
              return (
                <Package
                  length={p.packageLength / 1000}
                  height={p.packageHeight / 1000}
                  width={p.packageWidth / 1000}
                  key={'unplaced' + i}
                  posL={(packingResponse.truckTypeLength - p.packageLength) / 2}
                  posH={0}
                  posW={1.2 * packingResponse.truckTypeWidth + previousUnplacedPosW}
                  isUnplaced={true}
                  isSelected={isSelected}
                />
              );
            })}
            <TruckContainer length={truckLength / 1000} height={truckHeight / 1000} width={truckWidth / 1000} />
          </Physics>
        </Canvas>
      </div>
      <Typography sx={{ padding: '5px', paddingLeft: '10px' }} variant="h7" style={packingResponse?.status !== 'Success' ? { color: 'red' } : {}}>{`後方空き：${
        packingResponse?.backMargin ?? '?'
      } mm`}</Typography>
    </Paper>
  );
};

export default TruckDisplay;
