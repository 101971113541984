import { FETCH_ALL, CREATE, UPDATE, DELETE } from '../constants/actionTypes';
import * as api from '../api/index.js';
import { DB_ERROR_ANY, DB_ERROR_CONFLICT, DB_SUCCESS } from '../constants/dbResultCodes';

export const getPickUpPoints = () => async (dispatch) => {
  try {
    const { data } = await api.fetchPickUpPoints();

    dispatch({ type: FETCH_ALL, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const createPickUpPoint = (truck) => async (dispatch) => {
  try {
    const { data } = await api.createPickUpPoint(truck);

    dispatch({ type: CREATE, payload: data });
    return DB_SUCCESS;
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message?.includes('duplicate key value violates unique constraint')) {
      return DB_ERROR_CONFLICT;
    }
    return DB_ERROR_ANY;
  }
};

export const updatePickUpPoint = (id, truck) => async (dispatch) => {
  try {
    const { data } = await api.updatePickUpPoint(id, truck);

    dispatch({ type: UPDATE, payload: data });
    return DB_SUCCESS;
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message?.includes('duplicate key value violates unique constraint')) {
      return DB_ERROR_CONFLICT;
    }
    return DB_ERROR_ANY;
  }
};

export const deletePickUpPoint = (id) => async (dispatch) => {
  try {
    await await api.deletePickUpPoint(id);

    dispatch({ type: DELETE, payload: id });
    return DB_SUCCESS;
  } catch (error) {
    console.log(error);
    return DB_ERROR_ANY;
  }
};
