import React from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

class SmoothModeSwich extends React.Component {
  helpDiv;

  SmoothModeSwich(isSmoothMode, setIsSmoothMode) {
    const MapHelp = L.Control.extend({
      onAdd: (map) => {
         const helpDiv = L.DomUtil.create('div', 'smooth-mode');
         this.helpDiv = helpDiv;

        const checkBox = document.createElement("input");
        checkBox.type = `checkbox`;
        checkBox.id = `smooth-check-box`;
        checkBox.name = `smooth`;
        checkBox.checked= {isSmoothMode};
        checkBox.onchange = (e)=>{
          setIsSmoothMode(e.target.checked);
        }
        helpDiv.appendChild(checkBox);

        const label = document.createElement("label");
        label.for = `smooth`;
        label.innerText = `軽量モード`;
        helpDiv.appendChild(label);

        // 'click'、'doubleclick'、'mousedown'、'touchstart'のイベントを
        // 親要素（地図）に伝播しないようにする。
        // 'mousewheel'イベントの伝播も抑制したい場合は、
        // L.DomEvent.disableScrollPropagationも呼ぶこと。
        L.DomEvent.disableClickPropagation(helpDiv);

        return helpDiv;
      },
    });
    return new MapHelp({ position: 'bottomright' });
  }

  componentDidMount() {
    const {map, isSmoothMode, setIsSmoothMode} = this.props;
    const control = this.SmoothModeSwich(isSmoothMode, setIsSmoothMode);
    control.addTo(map);
  }

  componentWillUnmount() {
    this.helpDiv.remove();
  }

  render() {
    return null;
  }
}

function withMap(Component) {
  return function WrappedComponent(props) {
    const map = useMap();
    return <Component {...props} map={map} />;
  };
}

export default withMap(SmoothModeSwich);
