import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import logo from '../../images/logo.png';
import titleText from '../../images/geopacking.png';
import * as actionType from '../../constants/actionTypes';

import { SidebarData } from './SidebarData';

import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';

import { IconContext } from 'react-icons';

import './Navbar.css';
import { Grid, Paper } from '@mui/material';

const Navbar = ({ sidebarTrigger, setSidebarTrigger }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const logout = () => {
    dispatch({ type: actionType.LOGOUT });

    navigate('/auth');

    setUser(null);
  };

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('profile')));
  }, [location]);

  const toggleSidebar = () => setSidebarTrigger(!sidebarTrigger);

  useEffect(() => {
    setSidebarTrigger(sidebarTrigger);
  }, [sidebarTrigger, setSidebarTrigger]);

  return (
    <IconContext.Provider value={{ color: '#fff' }}>
      <Paper>
        <div className="navbar">
          <Link to="#" className="menuBars">
            <FaIcons.FaBars onClick={toggleSidebar} />
          </Link>
          <Link to={'/home'}>
            <Grid container spacing={2}>
              <Grid item>
                <img className="title" src={titleText} alt="icon" height="45px" />
              </Grid>
              <Grid item>
                <img src={logo} alt="icon" height="45px" />
              </Grid>
            </Grid>
          </Link>
        </div>
      </Paper>
      <Paper elevation={24}>
        <nav className={sidebarTrigger ? 'navMenu active' : 'navMenu'}>
          <ul className="navMenuItems" onClick={toggleSidebar}>
            <li className="navbarToggle">
              <Link to="#" className="menuBars">
                <AiIcons.AiOutlineClose />
              </Link>
            </li>
            {user?.result ? (
              SidebarData.map((item, index) => {
                return (
                  <li key={index} className={item.className}>
                    <Link to={item.path} onClick={item.path === '/auth' ? logout : ''}>
                      {item.icon}
                      <span>{item.title}</span>
                    </Link>
                  </li>
                );
              })
            ) : (
              <li className="navText">
                <Link to="/auth">
                  <AiIcons.AiOutlineLogin />
                  <span>ログイン</span>
                </Link>
              </li>
            )}
          </ul>
        </nav>
      </Paper>
    </IconContext.Provider>
  );
};

export default Navbar;
