import React from 'react';
import { Paper, Table, TableBody, TableContainer, Tooltip } from '@mui/material';
import { StyledTableCell, StyledTableRow, StyledTableHeaderInBodyCell } from './styles.js';
import { DateTime } from 'luxon';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { FC1_DOES_NOT_FIT, FC2_TIME_VIOLATION, FC3_TOO_MANY_DESTINATIONS, FC4_TOO_MANY_PICK_UP_POINTS, FC5_TOO_HEAVY, FC6_PERMITTED_TRUCKS_VIOLATION } from '../../constants/packingFailureReasons.js';
const TIMEZONE = 'UTC+9'; // 日本時間

const ResultBar = ({ resultId, packingResponse }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <StyledTableRow>
            <StyledTableHeaderInBodyCell>結果ID</StyledTableHeaderInBodyCell>
            <StyledTableCell>{resultId ?? ' - '}</StyledTableCell>
            <StyledTableHeaderInBodyCell>ステータス</StyledTableHeaderInBodyCell>
            <StyledTableCell style={packingResponse?.status !== 'Success' ? { color: 'red' } : {}}>{packingResponse?.status ?? ' - '}</StyledTableCell>
            <StyledTableHeaderInBodyCell>登録時刻</StyledTableHeaderInBodyCell>
            <StyledTableCell>{DateTime.fromJSDate(new Date(packingResponse?.registeredTime)).setZone(TIMEZONE).toFormat("yyyy'年'MM'月'dd'日　'HH':'mm':'ss'.'SSS") ?? ' - '}</StyledTableCell>
            <StyledTableHeaderInBodyCell>積み付け可能</StyledTableHeaderInBodyCell>
            <StyledTableCell style={!packingResponse?.stackable ? { color: 'red' } : {}}>{packingResponse?.stackable ? '可' : '不可'}</StyledTableCell>
            <StyledTableHeaderInBodyCell>
              失敗理由　
              <Tooltip
                title={
                  <div>
                    1: {FC1_DOES_NOT_FIT}
                    <br />
                    2: {FC2_TIME_VIOLATION}
                    <br />
                    3: {FC3_TOO_MANY_DESTINATIONS}
                    <br />
                    4: {FC4_TOO_MANY_PICK_UP_POINTS}
                    <br />
                    5: {FC5_TOO_HEAVY}
                    <br />
                    6: {FC6_PERMITTED_TRUCKS_VIOLATION}
                  </div>
                }
              >
                <HelpOutlineIcon fontSize="small" />
              </Tooltip>
            </StyledTableHeaderInBodyCell>
            <StyledTableCell style={!packingResponse?.stackable && packingResponse?.failureCodes?.length > 0 ? { color: 'red' } : {}}>
              {packingResponse?.failureCodes != null && packingResponse?.failureCodes !== '' ? packingResponse?.failureCodes : ' - '}
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ResultBar;
