import React from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

class MapAddressBox extends React.Component {
  helpDiv;

  createAddressControl(setAddress) {
    const MapHelp = L.Control.extend({
      onAdd: (map) => {
        const helpDiv = L.DomUtil.create('div', 'addressBox');
        this.helpDiv = helpDiv;
        helpDiv.innerHTML = `<input type="text" id="addressInput" value="" placeholder="住所入力で緯度経度を検索" size="60" />`;

        helpDiv.addEventListener('keypress', (e) => {
          if (e.key === 'Enter') {
            this.props.setAddress(document.getElementById('addressInput').value);
          }
        });

        // 'click'、'doubleclick'、'mousedown'、'touchstart'のイベントを
        // 親要素（地図）に伝播しないようにする。
        // これで住所検索ボックス上のマウス操作による地図ドラッグの防止や
        // 通常の右クリックメニューを表示することが可能となる。
        // 'mousewheel'イベントの伝播も抑制したい場合は、
        // L.DomEvent.disableScrollPropagationも呼ぶこと。
        L.DomEvent.disableClickPropagation(helpDiv);

        return helpDiv;
      },
    });
    return new MapHelp({ position: 'bottomleft' });
  }

  componentDidMount() {
    const { map, setAddress } = this.props;
    const control = this.createAddressControl(setAddress);
    control.addTo(map);
  }

  componentWillUnmount() {
    this.helpDiv.remove();
  }

  render() {
    return null;
  }
}

function withMap(Component) {
  return function WrappedComponent(props) {
    const map = useMap();
    return <Component {...props} map={map} />;
  };
}

export default withMap(MapAddressBox);
