import React, { useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import DepotMapDraggableMarker from './DepotMapDraggableMarker';
import FlashMessage from '../../Messages/FlashMessage';

let position = [36.7454436, 137.2276794];

const DepotMap = ({ depots }) => {
  // Only allow one draggable marker at a time
  const [isDragging, setIsDragging] = useState(false);

  // Flash message things
  const [message, setMessage] = useState('');
  const [flashTrigger, setFlashTrigger] = useState(50);

  if (depots.length > 0) {
    position = [depots[0].depotLat, depots[0].depotLng];
  } else {
    return;
  }

  return depots[0].depotLat === undefined ? (
    <></>
  ) : (
    <>
      {message && <FlashMessage message={message} flashTrigger={flashTrigger} />}
      <MapContainer center={position} zoom={10} scrollWheelZoom={true} gestureHandling={true}>
        <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {depots.map((depot) => {
          return (
            <DepotMapDraggableMarker
              key={depot._id}
              setMessage={setMessage}
              setFlashTrigger={setFlashTrigger}
              flashTrigger={flashTrigger}
              setIsDragging={setIsDragging}
              isDragging={isDragging}
              depot={depot}
            />
          );
        })}
      </MapContainer>
    </>
  );
};

export default DepotMap;
