import DOMPurify from 'dompurify';
import { DB_ERROR_ANY, DB_ERROR_CONFLICT, DB_SUCCESS } from '../../constants/dbResultCodes';
import { CONFIRM_DIALOG_DELETE } from '../../constants/dialogMessages';
import { ERROR, ERROR_CONFLICT, SUCCESS_CREATE, SUCCESS_DELETE, SUCCESS_EDIT } from '../../constants/flashMessages';
import { ERR_DIGIT_UPPER_LIMIT, ERR_DOUBLE, ERR_DUPLICATE, ERR_FORBIDDEN, ERR_GE0, ERR_GT0, ERR_INT, ERR_LAT, ERR_LNG, ERR_NUM_UPPER_LIMIT, ERR_VARCHAR100 } from '../../constants/formErrorHelpers';
import { NEW_LOCATION_CODE_STRING } from '../../constants/specialStrings';

/**
 * CSV用。CSVファイル名を作成する。
 * @param {*} dataTypeJP
 * @param {*} data
 * @returns
 */
export const prepareCsvFileName = (dataTypeJP, data) => {
  const date = new Date();
  const result = `${dataTypeJP.toLowerCase()}一覧（${data.length}件）${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}　${date.getHours()}時${date.getMinutes()}分`;
  return result;
};

/**
 * テーブル用。データ削除処理
 * @param {*} dataId
 * @param {*} setDialog
 */
export const handleDeleteData = (dataId, setDialog) => {
  setDialog({
    message: CONFIRM_DIALOG_DELETE,
    isOpen: true,
    dataIdentifier: dataId,
  });
};
export const confirmDialog = async (action, dataIdentifier, setDialog, setMessage, setFlashTrigger, flashTrigger, deleteData) => {
  if (action) {
    let dbResult = await deleteData(dataIdentifier);
    setDialog({ message: '', isOpen: false, dataIdentifier: '' });
    setMessage(dbResult === DB_SUCCESS ? SUCCESS_DELETE : ERROR);
    setFlashTrigger(flashTrigger + 1);
  } else {
    setDialog({ message: '', isOpen: false, dataIdentifier: '' });
  }
};

/**
 * テーブル表示用。緯度経度の値が長い場合、短くする。
 * @param {*} input
 * @returns
 */
export function shortenLatLng(input) {
  if (input == null) return;
  const string = input.toString();
  const split = string.split('.');
  let decimals;
  if (split.length === 2) {
    decimals = split[1].length > 7 ? split[1].substring(0, 6) + '...' : split[1];
  }
  return `${split[0]}${decimals ? '.' + decimals : ''}`;
}

/**
 * テーブルのフィルタ用。テーブルのスクロール位置を上に戻す。
 * @param {*} indexTable
 */
export function resetScrollInsideTable(indexTable) {
  const tableBody = document.getElementsByClassName('fixed-size-list')[indexTable];
  tableBody.scrollTop = 0;
}

/**
 * テーブルのフィルタ用。フィルタされたデータの文字をハイライトする。
 * @param {*} param0
 * @returns
 */
export function highlightableCell({ cell: { value }, state: { globalFilter: searchTerm } }) {
  if (!searchTerm) {
    return <div>{value}</div>;
  }

  const searchRegex = new RegExp(`(${escapeRegExp(searchTerm)})`, 'ig');
  const highlightedText = value.replace(searchRegex, '<span class="cell-search-highlight">$1</span>');

  return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(highlightedText) }} />;
}

const reRegExpChar = /[\\^$.*+?()[\]{}|]/g;
const reHasRegExpChar = RegExp(reRegExpChar.source);

function escapeRegExp(string) {
  return string && reHasRegExpChar.test(string) ? string.replace(reRegExpChar, '\\$&') : string || '';
}

/**
 * フォーム用。データ編集後のメッセージを決める。
 * @param {*} dbResult
 * @param {*} currentId
 * @param {*} setMessage
 * @param {*} clear
 */
export function setMessageAccordingToDbResult(dbResult, currentId, setMessage, clear) {
  switch (dbResult) {
    case DB_SUCCESS:
      setMessage(currentId === 0 || currentId == null ? SUCCESS_CREATE : SUCCESS_EDIT);
      clear();
      break;
    case DB_ERROR_CONFLICT:
      setMessage(ERROR_CONFLICT);
      break;
    case DB_ERROR_ANY:
    default:
      setMessage(ERROR);
      break;
  }
}

/**
 * フォーム用。入力がおかしくないか確認する。
 * @param {*} dataDetails
 * @param {*} textData
 * @param {*} allData
 * @param {*} currentId
 * @param {*} isErrorCopy
 * @param {*} setIsError
 * @param {*} errorHelperCopy
 * @param {*} setErrorHelper
 * @param {*} creatingNewDestinationLocationCode
 * @param {*} destinationLocations
 */
export const checkTypeAndRange = (
  dataDetails,
  textData,
  allData,
  currentId,
  isErrorCopy,
  setIsError,
  errorHelperCopy,
  setErrorHelper,
  creatingNewDestinationLocationCode = false,
  destinationLocations = null,
) => {
  dataDetails.forEach((d, i) => {
    if (i === dataDetails.length) return;
    if (d.accessor === 'destinationLocationCode' && creatingNewDestinationLocationCode && textData[d.accessor] === NEW_LOCATION_CODE_STRING) {
      isErrorCopy[i] = true;
      errorHelperCopy[i] = ERR_FORBIDDEN;
      return;
    }
    if ((d.type === 'text' || (d.accessor === 'destinationLocationCode' && creatingNewDestinationLocationCode)) && textData[d.accessor]?.length > 100) {
      isErrorCopy[i] = true;
      errorHelperCopy[i] = ERR_VARCHAR100;
      return;
    }
    if (
      d.accessor &&
      d.accessor.includes('Code') &&
      !d.accessor.includes('Permitted') &&
      ((d.accessor !== 'destinationLocationCode' && currentId === 0) || (d.accessor === 'destinationLocationCode' && creatingNewDestinationLocationCode))
    ) {
      const existingCode =
        d.accessor !== 'destinationLocationCode' ? allData.find((e) => e[d.accessor] === textData[d.accessor]) : destinationLocations.find((e) => e[d.accessor] === textData[d.accessor]);
      if (existingCode) {
        isErrorCopy[i] = true;
        errorHelperCopy[i] = ERR_DUPLICATE;
        return;
      }
    }
    if (d.type === 'number' && (d.accessor?.includes('Lat') || d.accessor?.includes('Lng')) && isNaN(textData[d.accessor])) {
      isErrorCopy[i] = true;
      errorHelperCopy[i] = ERR_DOUBLE;
      return;
    } else if (d.type === 'number' && (d.accessor?.includes('Lat') || d.accessor?.includes('Lng'))) {
      if (!Number.isFinite(textData[d.accessor])) {
        const split = textData[d.accessor]?.split('.');
        if (split.length > 1) {
          const DECIMALS_UPPER_LIMIT = 15; // https://www.postgresql.org/docs/current/datatype-numeric.html
          const decimals = split[1];
          if (decimals.length > DECIMALS_UPPER_LIMIT) {
            isErrorCopy[i] = true;
            errorHelperCopy[i] = ERR_DIGIT_UPPER_LIMIT;
            return;
          }
        }
      }
    }
    if (d.accessor?.includes('Lat') && (textData[d.accessor] < -90 || textData[d.accessor] > 90)) {
      isErrorCopy[i] = true;
      errorHelperCopy[i] = ERR_LAT;
      return;
    } else if (d.accessor?.includes('Lng') && (textData[d.accessor] < -180 || textData[d.accessor] > 180)) {
      isErrorCopy[i] = true;
      errorHelperCopy[i] = ERR_LNG;
      return;
    } else if (d.type === 'number' && !d.accessor?.includes('Lat') && !d.accessor?.includes('Lng')) {
      function isInteger(str) {
        const num = Number(str);
        return Number.isInteger(num);
      }

      if (!isInteger(textData[d.accessor]) && !Number.isInteger(textData[d.accessor])) {
        isErrorCopy[i] = true;
        errorHelperCopy[i] = ERR_INT;
        return;
      }
      const INTEGER_UPPER_LIMIT = 2147483647; // https://www.postgresql.org/docs/current/datatype-numeric.html
      if (textData[d.accessor] > INTEGER_UPPER_LIMIT) {
        isErrorCopy[i] = true;
        errorHelperCopy[i] = ERR_NUM_UPPER_LIMIT;
        return;
      }
      if (d.accessor?.includes('Height') || d.accessor?.includes('Width') || d.accessor?.includes('Length') || d.accessor?.includes('Weight')) {
        if (textData[d.accessor] <= 0) {
          isErrorCopy[i] = true;
          errorHelperCopy[i] = ERR_GT0;
          return;
        } else {
          isErrorCopy[i] = false;
          errorHelperCopy[i] = '';
          return;
        }
      } else {
        if (textData[d.accessor] < 0) {
          isErrorCopy[i] = true;
          errorHelperCopy[i] = ERR_GE0;
          return;
        } else {
          isErrorCopy[i] = false;
          errorHelperCopy[i] = '';
          return;
        }
      }
    } else {
      isErrorCopy[i] = false;
      errorHelperCopy[i] = '';
    }
  });
  setIsError(isErrorCopy);
  setErrorHelper(errorHelperCopy);
};
