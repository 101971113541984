import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import { resetScrollInsideTable } from './commonFunctions';

// テーブルの検索機能
export const Filter = ({ filter, setFilter }) => {
  const [value, setValue] = useState(filter);

  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined);
    resetScrollInsideTable(0);
  }, 400);

  return (
    <span>
      検索：　
      <input
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        onClick={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
    </span>
  );
};

export default Filter;
