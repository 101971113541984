import React, { useMemo, useState, useEffect, useContext, useCallback, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTable, useGlobalFilter, useBlockLayout } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { FixedSizeList } from 'react-window';
import { IconButton, Paper, TableContainer, Backdrop, Grid } from '@mui/material';
import { FaTrash, FaPen } from 'react-icons/fa';
import { Download, AddCircleOutline, MyLocation, Upload } from '@mui/icons-material';
import { CSVLink } from 'react-csv';

import DestinationForm from './DestinationForm';
import Filter from '../Filter';
import FlashMessage from '../../Messages/FlashMessage';
import ConfirmDialog from '../../Messages/ConfirmDialog';
import LoadRemarkNames from './LoadRemarkNames';
import DestinationFileUploadForm from './DestinationFileUploadForm';
import { DivStyles } from '../DivStyles';
import { DisplayDestinationMarkersContext } from '../../Maps/DestinationMap/DisplayDestinationMarkersContext';
import { GoToMarkerContext } from '../../Maps/DestinationMap/GoToMarkerContext';
import { DestinationRowsContext} from '../../Maps/DestinationMap/DestinationRowsContext';
import { DestinationMasterColumns } from './Columns/DestinationMasterColumns';

import { deleteDestination, getDestinationRemarkNames } from '../../../actions/destinations';
import { confirmDialog, handleDeleteData, prepareCsvFileName, shortenLatLng } from '../commonFunctions.js';

import { DESTINATIONS_JP } from '../../../constants/dataTypes';

const DataTable = ({ data }) => {
  const { setDisplayDestinationMarkersTrigger } = useContext(DisplayDestinationMarkersContext);
  const { setGoToMarker } = useContext(GoToMarkerContext);
  const { setDestinationRows } = useContext(DestinationRowsContext);
  const dispatch = useDispatch();
  const [currentId, setCurrentId] = useState(0);

  // フラッシュメッセージ用
  const [message, setMessage] = useState('');
  const [flashTrigger, setFlashTrigger] = useState(0);

  // フォーム用
  const [openForm, setOpenForm] = useState(false);
  const handleToggleForm = useCallback(() => {
    setCurrentId(0);
    setOpenForm(!openForm);
  }, [openForm]);

  // ファイルアップロードフォーム用
  const [openFileUploadForm, setOpenFileUploadForm] = useState(false);
  const handleToggleFileUploadForm = useCallback(() => {
    setOpenFileUploadForm(!openFileUploadForm);
  }, [openFileUploadForm]);

  // 納入先備考のカラム名と納入先位置データ用
  const destinationRemarkNames = useSelector((state) => state.destinationRemarkNames);
  const destinationLocations = useSelector((state) => state.destinationLocations);

  // CSV
  const prepareCsvData = () => {
    const dataForCsv = [];

    data.forEach((d) => {
      const { _id, ...dataWithout_id } = d;
      const destinationLocation = destinationLocations.find((o) => o.destinationLocationCode === dataWithout_id.destinationLocationCode);
      dataWithout_id.destinationLat = shortenLatLng(destinationLocation?.destinationLat);
      dataWithout_id.destinationLng = shortenLatLng(destinationLocation?.destinationLng);
      dataForCsv.push(dataWithout_id);
    });

    const japaneseHeaders = [];
    if (rows?.length > 0 && rows[0]?.allCells != null) {
      rows[0]?.allCells.forEach((e, i, arr) => {
        if (i < arr.length - 1 && !e.column.Header.includes('備考')) japaneseHeaders.push([e.column.Header, e.column.accessorForCsv]);
      });
    }

    if (destinationRemarkNames.length > 0) {
      const { _id, ...remarkNamesWithout_id } = destinationRemarkNames[0];
      dataForCsv.forEach((d) => {
        japaneseHeaders.forEach((e) => {
          d[e[0]] = d[e[1]];
          delete d[e[1]];
        });
        for (const [key, value] of Object.entries(remarkNamesWithout_id)) {
          d[value] = d[key];
          delete d[key];
        }
      });
    }

    return dataForCsv;
  };

  // 編集可能なカラム用
  let editableColumnNames = useMemo(() => [], []);
  let dataColumns = useMemo(() => [], []);

  const [editingColumnNames, setEditingColumnNames] = useState(false);
  const handleToggleColumnNameUpdateForm = useCallback(
    (id) => {
      setCurrentId(id);
      setEditingColumnNames(true);
      setOpenForm(!openForm);
    },
    [openForm],
  );

  // 日本語
  let dataTypeJP = '';

  dataTypeJP = DESTINATIONS_JP;
  dataColumns = DestinationMasterColumns;

  // データ削除
  const deleteData = async (id) => {
    const result = await dispatch(deleteDestination(id));
    setDisplayDestinationMarkersTrigger(true);
    return result;
  };
  editableColumnNames = destinationRemarkNames;

  // 動作確認ダイアログ（データ削除用）
  const [dialog, setDialog] = useState({
    message: '',
    isOpen: false,
    dataIdentifier: '',
  });

  // テーブル
  const columns = useMemo(() => dataColumns, [dataColumns]);
  // 「デフォルト」の幅を定義する
  // Columns/〇〇Columns.jsで幅が指定されてない時にこれを使う
  // （つまり納入先備考カラム用）
  const defaultColumn = React.useMemo(
    () => ({
      width: 200,
    }),
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, totalColumnsWidth, prepareRow, state, setGlobalFilter } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useGlobalFilter,
    useBlockLayout,
    useSticky,
  );
  const { globalFilter } = state;

  useEffect(() => {
    dispatch(getDestinationRemarkNames());
  }, [dispatch]);

  useEffect(() => {
    if (currentId) setOpenForm(true);
  }, [currentId]);

  /**
   *
   * 以下：納入先備考カラム名が長い場合の左右スクロールの処理
   *
   */

  // 備考カラム毎にrefを作る
  const [scrollRefs, setScrollRefs] = useState([]);
  useEffect(() => {
    setScrollRefs((scrollRefs) =>
      Array(30)
        .fill()
        .map((_, i) => scrollRefs[i] || createRef()),
    );
  }, [setScrollRefs]);

  // 備考カラム毎に左右スクロールボタンが必要かどうか
  const [needsScrollArrows, setNeedsScrollArrows] = useState(new Array(30).fill(false));
  // 備考名が変わったらトリガー
  const [remarkNamesTrigger, setRemarkNamesTrigger] = useState(true);
  // 左右スクロールボタンが必要かどうか判断するために、文字列の幅を計算する
  const getTextWidth = useCallback((text, font) => {
    const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'));
    const context = canvas.getContext('2d');
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  }, []);

  // 左右スクロールボタン有無を更新する
  const updateRemarkNameScrollButtons = useCallback(() => {
    const needsScrollArrowsCopy = JSON.parse(JSON.stringify(needsScrollArrows));
    let somethingChanged = false;
    for (let remarkNo = 0; remarkNo < 30; remarkNo++) {
      if (getTextWidth(scrollRefs[remarkNo]?.current.innerText, 'bold 12pt Heebo') > 192) {
        if (!needsScrollArrows[remarkNo]) {
          needsScrollArrowsCopy[remarkNo] = true;
          somethingChanged = true;
        }
      } else {
        if (needsScrollArrows[remarkNo]) {
          needsScrollArrowsCopy[remarkNo] = false;
          somethingChanged = true;
        }
      }
    }
    if (somethingChanged) setNeedsScrollArrows(needsScrollArrowsCopy);
  }, [getTextWidth, needsScrollArrows, scrollRefs]);

  // 上記トリガーによって左右スクロールボタン有無を更新する
  useEffect(() => {
    if (remarkNamesTrigger) {
      setRemarkNamesTrigger(false);
      updateRemarkNameScrollButtons();
    }
  }, [setNeedsScrollArrows, scrollRefs, remarkNamesTrigger, updateRemarkNameScrollButtons]);

  // 左右スクロールボタンの処理
  const slide = useCallback(
    (remarkNo, shift) => {
      scrollRefs[remarkNo].current.scrollLeft += shift;
    },
    [scrollRefs],
  );

  //フィルターでテーブルの表示内容が変わったとき、それを地図に反映させる
  useEffect(()=>{
    let formattedRows=[];
    rows.forEach(row => {
      formattedRows.push(row.values);
    });
    setDestinationRows(formattedRows);
  }, [rows, setDestinationRows]);

  /**
   * https://github.com/GuillaumeJasmin/react-table-sticky/issues/5
   * 以下TableWrapper＝テーブルのヘッダー、RenderRows＝テーブルの中身。
   * 複雑な作りですが、
   * react-table,
   * react-window（見える要素のみをレンダリングするパッケージ：納入先マスタに不可欠）,
   * react-sticky（納入先テーブルのボタンのカラムを固定するパッケージ）,
   * を同時に使うには、こうしなければならない。
   */
  const TableWrapper = useCallback(
    ({ children, style, ...rest }) => {
      return (
        <>
          <div className="header" style={{ width: 'fit-content' }}>
            <div style={{ height: 50, top: 10, width: totalColumnsWidth + 8 }}>
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="tr" style={{ display: 'flex', width: 'fit-content' }}>
                  {headerGroup.headers.map((column, i, columns) => {
                    return column.Header === '指定時刻（から）' ? (
                      ''
                    ) : column.Header === '指定時刻（まで）' ? (
                      // 「から」と「まで」を一つの「指定時刻」カラムに纏める
                      <div {...column.getHeaderProps()} sx={{ colSpan: 2 }} className="th">
                        指定時刻
                      </div>
                    ) : i !== columns.length - 1 ? (
                      // 残りのヘッダー。最後のカラムは無視する（ボタン用）。緯度経度の場合、固定幅にする。納入先備考名がある場合は、ユーザが決めたカラム名を表示する。
                      <React.Fragment key={'c' + column.Header}>
                        {column.name?.includes('remark') && needsScrollArrows[parseInt(column.name?.substring(6)) - 1] && (
                          <button
                            style={{ color: 'white', backgroundColor: 'transparent', border: 'none', cursor: 'pointer', fontSize: '12px' }}
                            size="small"
                            onClick={() => slide(parseInt(column.name?.substring(6)) - 1, -50)}
                          >
                            &lt;
                          </button>
                        )}
                        <div
                          {...column.getHeaderProps()}
                          className="th"
                          ref={column.name?.includes('remark') ? scrollRefs[parseInt(column.name?.substring(6)) - 1] : null}
                          style={
                            { width: column.width - (column.name?.includes('remark') && needsScrollArrows[parseInt(column.name?.substring(6)) - 1] ? 36 : 0) ?? defaultColumn.width }
                            // TODO:　上記の「36」は、Macで開発したときは別の値（28.4）が必要でした。普遍的な書き方があれば直したい。
                          }
                        >
                          {
                            // prettier-ignore
                            column.isEditable
                            ? (
                                editableColumnNames.length > 0
                                ? editableColumnNames[0][column.name]
                                : column.render('Header')
                              )
                          : column.render('Header')
                          }
                        </div>
                        {column.name?.includes('remark') && needsScrollArrows[parseInt(column.name?.substring(6)) - 1] && (
                          <button
                            style={{ color: 'white', backgroundColor: 'transparent', border: 'none', cursor: 'pointer', fontSize: '12px' }}
                            size="small"
                            onClick={() => slide(parseInt(column.name?.substring(6)) - 1, +50)}
                          >
                            &gt;
                          </button>
                        )}
                      </React.Fragment>
                    ) : (
                      // ボタン
                      <div {...column.getHeaderProps()} className="th">
                        <IconButton style={{ color: 'white', marginTop: '-9px' }} size="small" onClick={() => handleToggleColumnNameUpdateForm(editableColumnNames[0]['_id'])}>
                          <FaPen fontSize="default" />
                        </IconButton>
                        <IconButton onClick={handleToggleForm}>
                          <AddCircleOutline style={{ color: 'white', marginTop: '-9px' }} />
                        </IconButton>
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          </div>
          <div style={{ height: 500 - 58 - 57 }} className="body">
            <div {...getTableBodyProps()} {...rest} style={style}>
              {children}
            </div>
          </div>
        </>
      );
    },
    [editableColumnNames, getTableBodyProps, handleToggleColumnNameUpdateForm, handleToggleForm, headerGroups, totalColumnsWidth, defaultColumn, scrollRefs, needsScrollArrows, slide],
  );

  const RenderRows = useCallback(
    ({ index, style }) => {
      const row = rows[index];
      if (!row) return <div>Loading...</div>;

      prepareRow(row);
      const { style: rowStyle, ...restRow } = row.getRowProps({ style });
      return (
        <div {...restRow} style={{ ...rowStyle, top: rowStyle.top + 50, left: 8, width: totalColumnsWidth + 8 }} className="tr">
          {row.cells.map((cell, j, cells) => {
            return columns[j].accessor === 'desiredTimeStart' ? (
              ''
            ) : columns[j].accessor === 'desiredTimeEnd' ? (
              // 「から」と「まで」を一つの「指定時刻」カラムに纏める
              <div {...cell.getCellProps()} className="td">
                {cells[j - 1].render('Cell')} {cells[j - 1].value && cell.value ? '~' : cells[j - 1].value ? 'から' : ''} {cell.render('Cell')} {!cells[j - 1].value && cell.value ? 'まで' : ''}
              </div>
            ) : columns[j].accessor === 'destinationLocationCode' ? (
              <div {...cell.getCellProps()} className="td">
                <IconButton style={{ color: 'black' }} size="small" onClick={() => setGoToMarker(destinationLocations.find((o) => o.destinationLocationCode === cell.value))}>
                  <MyLocation fontSize="default" />
                </IconButton>
                {cell.render('Cell')}
              </div>
            ) : j !== cells.length - 1 ? (
              // 残りのデータ。最後のカラムは無視する（ボタン用）。納入先の緯度経度の場合は、別のstate配列からデータを取得する。
              <div {...cell.getCellProps()} className={'td'} style={{ width: columns[j].width ?? defaultColumn.width }}>
                {columns[j].accessor === 'destinationLat' && destinationLocations.length > 0 && data.length > 0
                  ? shortenLatLng(destinationLocations.find((o) => o.destinationLocationCode === cells[2].value)?.destinationLat)
                  : columns[j].accessor === 'destinationLng' && destinationLocations.length > 0 > 0 && data.length > 0
                  ? shortenLatLng(destinationLocations.find((o) => o.destinationLocationCode === cells[2].value)?.destinationLng)
                  : cell.render('Cell')}
              </div>
            ) : (
              // ボタン
              <div {...cell.getCellProps()} className="td">
                <IconButton style={{ color: 'black' }} size="small" onClick={() => setCurrentId(cells[cells.length - 1].value)}>
                  <FaPen fontSize="default" />
                </IconButton>
                <IconButton style={{ color: 'black' }} size="small" onClick={() => handleDeleteData(cells[cells.length - 1].value, setDialog)}>
                  <FaTrash fontSize="default" />
                </IconButton>
              </div>
            );
          })}
        </div>
      );
    },
    [columns, data, destinationLocations, prepareRow, rows, totalColumnsWidth, defaultColumn, setGoToMarker],
  );

  return columns.length === 0 ? (
    <></>
  ) : (
    <>
      {message && <FlashMessage message={message} flashTrigger={flashTrigger} />}
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openFileUploadForm}>
        <DestinationFileUploadForm 
          openForm={openFileUploadForm}
          setOpenForm={setOpenFileUploadForm}
        />
      </Backdrop>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openForm}>
        <DestinationForm
          setMessage={setMessage}
          flashTrigger={flashTrigger}
          setFlashTrigger={setFlashTrigger}
          openForm={openForm}
          setOpenForm={setOpenForm}
          currentId={currentId}
          setCurrentId={setCurrentId}
          editingColumnNames={editingColumnNames}
          setEditingColumnNames={setEditingColumnNames}
          allData={data}
          setRemarkNamesTrigger={setRemarkNamesTrigger}
        />
      </Backdrop>
      <DivStyles>
        <TableContainer component={Paper} sx={{ marginBottom: '40px' }}>
          <Grid container spacing={2} sx={{ paddingLeft: '16px' }}>
            <Grid item xs={12} sx={{ marginTop: '15px', marginBottom: '15px' }}>
              {/* 検索 */}
              <Filter filter={globalFilter} setFilter={setGlobalFilter} />
              　　CSV:
              <CSVLink data={prepareCsvData()} filename={prepareCsvFileName(dataTypeJP, data)} target="_blank">
                <Download fontSize="large" sx={{ marginLeft: '8px' }} />
              </CSVLink>
              　　インポート:
              <IconButton onClick={handleToggleFileUploadForm}>
                <Upload fontSize="large" sx={{ marginLeft: '8px' }} />
              </IconButton>
            </Grid>
            <div width="100%" {...getTableProps()} style={{ marginBottom: '0px' }} className="table sticky">
              <FixedSizeList className={'fixed-size-list'} height={499} itemCount={rows.length} itemSize={50} width="100%" innerElementType={TableWrapper}>
                {RenderRows}
              </FixedSizeList>
            </div>
          </Grid>
        </TableContainer>
      </DivStyles>

      {dialog.isOpen && (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <ConfirmDialog
            onDialog={confirmDialog}
            message={dialog.message}
            dataIdentifier={dialog.dataIdentifier}
            setDialog={setDialog}
            setMessage={setMessage}
            setFlashTrigger={setFlashTrigger}
            flashTrigger={flashTrigger}
            deleteData={deleteData}
          />
        </Backdrop>
      )}

      <LoadRemarkNames dispatch={dispatch} setRemarkNamesTrigger={setRemarkNamesTrigger} />
    </>
  );
};

export default DataTable;
