import React from 'react';
import { Paper, Table, TableBody, TableContainer } from '@mui/material';
import { StyledTableCell, StyledTableRow, StyledTableHeaderInBodyCell } from './styles.js';

const RouteHeader = ({ packingResponse }) => {
  const routeFailed = packingResponse?.status !== 'Success' || (packingResponse?.failureCodes?.includes('2') && packingResponse?.totalDistance === 0);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <StyledTableRow>
            <StyledTableHeaderInBodyCell>トラック名称</StyledTableHeaderInBodyCell>
            <StyledTableCell colSpan={3} style={!packingResponse?.truckTypeName ? { color: 'red' } : {}}>
              {packingResponse?.truckTypeName ?? ' - '}
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableHeaderInBodyCell>総距離</StyledTableHeaderInBodyCell>
            <StyledTableCell style={routeFailed ? { color: 'red' } : {}}>
              {packingResponse?.totalDistance != null ? `${Math.floor(packingResponse?.totalDistance / 100) / 10} km` : ' - '}
            </StyledTableCell>
            <StyledTableHeaderInBodyCell>総稼働時間</StyledTableHeaderInBodyCell>
            <StyledTableCell style={routeFailed ? { color: 'red' } : {}}>
              {
                //prettier-ignore
                packingResponse?.totalTime
                  ? `${packingResponse?.totalTime.split(':')[0].substring(0, 1) === '0' 
                      ? packingResponse?.totalTime.split(':')[0].substring(1) 
                      : packingResponse?.totalTime.split(':')[0]}
                      時間
                    ${packingResponse?.totalTime.split(':')[1]}分`
                  : ' - '
              }
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RouteHeader;
