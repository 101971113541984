import React, { useState, useRef } from 'react'
import { useAsyncCallback } from 'react-async-hook'
import Box from '@mui/material/Box'
import CircularIntegration from './CircularIntegration.jsx';
import { uploadDestinationFile } from '../../../api';

const initialState = {
  selected: false,
  file: null,
}

const DestinationFileUploadButton = () => {
  const inputRef = useRef(null);
  const [formState, setFormState] = useState(initialState);
  const [messages, setMessages] = useState([]);

  // ファイル選択ボタンを押したとき
  const clickFileSelectButton = () => {
    inputRef.current.click(); //本来のファイル選択ボタンをクリック
  }

  // 選択ファイルを変更したとき
  const onFileInputChange = async (event) => {
    if(event.target.files.length >= 1){
      setFormState({
        selected: true,
        file: event.target.files[0],
      });
    }else{
      setFormState({
        selected: false,
        file: null,
      });
    }
  }

  // インポートボタンを押したとき
  const onClickFileUploadButton = async () => {
    await uploadFile(formState.file);
  }

  // アップロード
  const uploadFile = async(file) => {
    if (!file) return

    const form = document.getElementById('uploadForm');
    const formData = new FormData(form);
    const result = await uploadDestinationFile(formData);

    //納入先コード○○はスキップしました
    //○件のデータを更新しました
    console.log(result);
    setMessages(result.data);

    /* アップロード処理が成功したらフォームの状態を初期化してする */
    setFormState(initialState)
  }

  const onFileInputChangeAsync = useAsyncCallback(onFileInputChange);
  const onClickFileUploadButtonAsync = useAsyncCallback(onClickFileUploadButton);

  return (
    <Box>
      <form id="uploadForm">
        <div style={{'display': 'flex','alignItems': 'center'}}>
          <CircularIntegration
            onClick={clickFileSelectButton}
            asyncEvent={onFileInputChangeAsync}
            enable="false"
            component="label"
            text={"ファイル選択"}/>
          <div>{formState.selected ? formState.file.name : ''}</div>
        </div>
        <CircularIntegration
          asyncEvent={onClickFileUploadButtonAsync}
          component="label"
          enable={formState.selected}
          text={onClickFileUploadButtonAsync.loading ? '...' : "インポート"}
        /> 
        <ul style={{'listStyle':'none'}}>
          {messages.map((message, i) => {
            return (<li key={i}>{message}</li>);
          })}
        </ul>
        <input
          hidden
          ref={inputRef}
          type="file"
          name="file1"
          onChange={onFileInputChangeAsync.execute}
        />
      </form>
    </Box>
  )
}

export default DestinationFileUploadButton