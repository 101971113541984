import React, { useState, useRef, useEffect } from 'react';
import { Container, createTheme, ThemeProvider } from '@mui/material';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Navbar from './components/Navbar/Navbar';
import Auth from './pages/Auth/Auth';
import './App.css';
import Packing3D from './pages/Packing3D/Packing3D';
import TruckMaster from './pages/DataMasters/TruckMaster';
import PackageMaster from './pages/DataMasters/PackageMaster';
import DepotMaster from './pages/DataMasters/DepotMaster';
import PickUpPointMaster from './pages/DataMasters/PickUpPointMaster';
import DestinationMaster from './pages/DataMasters/DestinationMaster/DestinationMaster';
import CssBaseline from '@mui/material/CssBaseline';
import Home from './pages/Home/Home';
import FlashMessage from './components/Messages/FlashMessage';
// eslint-disable-next-line
import { GestureHandling } from 'leaflet-gesture-handling'; // ※　使わないけど、こう書かないとCTRL＋スクロール機能がちゃんと動かない気がする。

const App = () => {
  const lightTheme = createTheme({});

  const [sidebarTrigger, setSidebarTrigger] = useState(false);
  const navbarRef = useRef();

  // フラッシュメッセージ用
  const [message, setMessage] = useState('');
  const [flashTrigger, setFlashTrigger] = useState(0);

  useEffect(() => {
    document.addEventListener('mousedown', (e) => {
      if (navbarRef.current !== null && !navbarRef.current.contains(e.target)) {
        setSidebarTrigger(false);
      }
    });
  });

  return (
    <ThemeProvider theme={lightTheme}>
      {message && <FlashMessage message={message} flashTrigger={flashTrigger} />}
      <CssBaseline />
      <BrowserRouter>
        <Container maxWidth="xl">
          <div ref={navbarRef}>
            <Navbar sidebarTrigger={sidebarTrigger} setSidebarTrigger={setSidebarTrigger} />
          </div>
          <Routes>
            <Route exact path="/" element={<Navigate to="/home" />} />
            <Route exact path="/home" element={<Home />} />
            <Route exact path="/auth" element={<Auth setMessage={setMessage} flashTrigger={flashTrigger} setFlashTrigger={setFlashTrigger} />} />
            <Route exact path="/truck" element={<TruckMaster />} />
            <Route exact path="/package" element={<PackageMaster />} />
            <Route exact path="/depot" element={<DepotMaster />} />
            <Route exact path="/pickUpPoint" element={<PickUpPointMaster />} />
            <Route exact path="/destination" element={<DestinationMaster />} />

            <Route exact path="/packing3d/id:resultId/ol:overlappingHeight/token=:token" element={<Packing3D />} />
          </Routes>
        </Container>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
