import { FETCH_PACKING_RESPONSE, FETCH_ALL_PACKING_RESPONSES } from '../constants/actionTypes';

const packingResponsesReducer = (packingResponses = [], action) => {
  switch (action.type) {
    case FETCH_PACKING_RESPONSE:
      return action.payload;
    case FETCH_ALL_PACKING_RESPONSES:
      return action.payload;
    default:
      return packingResponses;
  }
};

export default packingResponsesReducer;
