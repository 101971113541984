export const ERR_VARCHAR100 = '100文字以内にしてください。';
export const ERR_LAT = '-90と90の間の数字を入力してください。';
export const ERR_LNG = '-180と180の間の数字を入力してください。';
export const ERR_GT0 = '0より大きい数字を入力してください。';
export const ERR_GE0 = '0以上の数字を入力してください。';
export const ERR_NUM_UPPER_LIMIT = '数字が大きすぎです。';
export const ERR_DIGIT_UPPER_LIMIT = '15桁以下の小数を入力してください。';
export const ERR_INT = '整数を入力してください。';
export const ERR_DOUBLE = '数字を入力してください。';
export const ERR_FORBIDDEN = 'この値は禁止されています。';
export const ERR_DUPLICATE = 'このコードは既に登録されています。';
