import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@mui/material';

import { getTrucks } from '../../actions/trucks';
import DataTable from '../../components/Table/NotDestination/DataTable';
import { TRUCKS } from '../../constants/dataTypes';

const TruckMaster = () => {
  const [currentId] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTrucks());
  }, [currentId, dispatch]);

  const trucks = useSelector((state) => state.data);

  return (
    <Grid container spacing={3} sx={{ paddingTop: '20px' }}>
      <Grid item xs={12} className="table-wrapper">
        <DataTable data={trucks} dataType={TRUCKS} />
      </Grid>
    </Grid>
  );
};

export default TruckMaster;
