import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Paper } from '@mui/material';

import DepotMap from '../../components/Maps/DepotMap/DepotMap';
import { getDepots } from '../../actions/depots';
import DataTable from '../../components/Table/NotDestination/DataTable';
import { DEPOTS } from '../../constants/dataTypes';

const Depots = () => {
  const [currentId] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDepots());
  }, [currentId, dispatch]);

  const depots = useSelector((state) => state.data);

  return (
    <Grid container spacing={3} sx={{ paddingTop: '20px' }}>
      <Grid item xs={6} className="table-wrapper">
        <DataTable data={depots} dataType={DEPOTS} />
      </Grid>
      <Grid item xs={6}>
        <Paper>
          <DepotMap depots={depots} />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Depots;
