import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTable, useGlobalFilter, useBlockLayout } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { FixedSizeList } from 'react-window';
import { IconButton, Paper, TableContainer, Backdrop, Grid } from '@mui/material';
import { FaTrash, FaPen } from 'react-icons/fa';
import { Download, AddCircleOutline } from '@mui/icons-material';
import { CSVLink } from 'react-csv';

import DataForm from './DataForm';
import Filter from '../Filter';
import FlashMessage from '../../Messages/FlashMessage';
import ConfirmDialog from '../../Messages/ConfirmDialog';
import { DivStyles } from '../DivStyles';

import { DepotMasterColumns } from './Columns/DepotMasterColumns';
import { PickUpPointMasterColumns } from './Columns/PickUpPointMasterColumns';
import { PackageMasterColumns } from './Columns/PackageMasterColumns';
import { TruckMasterColumns } from './Columns/TruckMasterColumns';

import { deleteDepot } from '../../../actions/depots';
import { deleteTruck } from '../../../actions/trucks';
import { deletePackage } from '../../../actions/packages';
import { deletePickUpPoint } from '../../../actions/pickUpPoints';
import { confirmDialog, handleDeleteData, prepareCsvFileName } from '../commonFunctions';

import { TRUCKS, PACKAGES, DEPOTS, PICK_UP_POINTS, TRUCKS_JP, DEPOTS_JP, PACKAGES_JP, PICK_UP_POINTS_JP } from '../../../constants/dataTypes';

const DataTable = ({ data, dataType }) => {
  const dispatch = useDispatch();
  const [currentId, setCurrentId] = useState(0);

  // フラッシュメッセージ用
  const [message, setMessage] = useState('');
  const [flashTrigger, setFlashTrigger] = useState(0);

  // フォーム用
  const [openForm, setOpenForm] = useState(false);
  const handleToggleForm = useCallback(() => {
    setCurrentId(0);
    setOpenForm(!openForm);
  }, [openForm]);

  // CSV
  const prepareCsvData = () => {
    const dataForCsv = [];

    data.forEach((d) => {
      const { _id, ...dataWithout_id } = d;
      dataForCsv.push(dataWithout_id);
    });

    const japaneseHeaders = [];
    if (rows?.length > 0 && rows[0]?.allCells != null) {
      rows[0]?.allCells.forEach((e, i, arr) => {
        if (i < arr.length - 1) japaneseHeaders.push([e.column.Header, e.column.accessorForCsv]);
      });
    }

    dataForCsv.forEach((d) => {
      japaneseHeaders.forEach((e) => {
        d[e[0]] = d[e[1]];
        delete d[e[1]];
      });
    });
    return dataForCsv;
  };

  let dataColumns = useMemo(() => [], []);

  // データ削除（画面によって決まる）
  let deleteData = () => {};

  // 日本語
  let dataTypeJP = '';

  switch (dataType) {
    case TRUCKS:
      dataTypeJP = TRUCKS_JP;
      dataColumns = TruckMasterColumns;
      deleteData = async (id) => {
        return await dispatch(deleteTruck(id));
      };
      break;
    case DEPOTS:
      dataTypeJP = DEPOTS_JP;
      dataColumns = DepotMasterColumns;
      deleteData = async (id) => {
        return await dispatch(deleteDepot(id));
      };
      break;
    case PACKAGES:
      dataTypeJP = PACKAGES_JP;
      dataColumns = PackageMasterColumns;
      deleteData = async (id) => {
        return await dispatch(deletePackage(id));
      };
      break;
    case PICK_UP_POINTS:
      dataTypeJP = PICK_UP_POINTS_JP;
      dataColumns = PickUpPointMasterColumns;
      deleteData = async (id) => {
        return await dispatch(deletePickUpPoint(id));
      };
      break;
    default:
      break;
  }

  // 動作確認ダイアログ（データ削除用）
  const [dialog, setDialog] = useState({
    message: '',
    isOpen: false,
    dataIdentifier: '',
  });

  // テーブル
  const columns = useMemo(() => dataColumns, [dataColumns]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useBlockLayout,
    useSticky,
  );
  const { globalFilter } = state;

  useEffect(() => {
    if (currentId) setOpenForm(true);
  }, [currentId]);

  /**
   * https://github.com/GuillaumeJasmin/react-table-sticky/issues/5
   * 以下TableWrapper＝テーブルのヘッダー、RenderRows＝テーブルの中身。
   */
  const TableWrapper = useCallback(
    ({ children, style, ...rest }) => {
      return (
        <>
          <div className="header" style={{ width: '100%' }}>
            <div style={{ height: 50, top: 10, width: '100%' }}>
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="tr" style={{ display: 'flex', width: '100%' }}>
                  {headerGroup.headers.map((column, i, columns) => {
                    return i !== columns.length - 1 ? (
                      // 最後のカラムは無視する（ボタン用）
                      <div {...column.getHeaderProps()} className="th" style={{ paddingLeft: 8, width: column.percentWidth }}>
                        {column.render('Header')}
                      </div>
                    ) : (
                      // ボタン
                      <div {...column.getHeaderProps()} style={{ width: column.percentWidth, textAlign: 'center' }} className="th">
                        <IconButton onClick={handleToggleForm}>
                          <AddCircleOutline style={{ color: 'white', marginTop: '-9px' }} />
                        </IconButton>
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          </div>
          <div style={{ height: 500 - 58 - 57 }} className="body">
            <div {...getTableBodyProps()} {...rest} style={style}>
              {children}
            </div>
          </div>
        </>
      );
    },
    [getTableBodyProps, handleToggleForm, headerGroups],
  );

  const RenderRows = useCallback(
    ({ index, style }) => {
      const row = rows[index];
      if (!row) return <div>Loading...</div>;

      prepareRow(row);
      const { style: rowStyle, ...restRow } = row.getRowProps({ style });
      return (
        <div {...restRow} style={{ ...rowStyle, top: rowStyle.top + 50, left: 8 }} className="tr">
          {row.cells.map((cell, j, cells) => {
            return j !== cells.length - 1 ? (
              // 最後のカラムは無視する（ボタン用）。
              <div {...cell.getCellProps()} className={'td'} style={{ width: columns[j].percentWidth }}>
                {cell.render('Cell')}
              </div>
            ) : (
              // ボタン
              <div {...cell.getCellProps()} style={{ width: columns[j].percentWidth }} className="td">
                <IconButton style={{ color: 'black' }} size="small" onClick={() => setCurrentId(cells[cells.length - 1].value)}>
                  <FaPen fontSize="default" />
                </IconButton>
                <IconButton style={{ color: 'black' }} size="small" onClick={() => handleDeleteData(cells[cells.length - 1].value, setDialog)}>
                  <FaTrash fontSize="default" />
                </IconButton>
              </div>
            );
          })}
        </div>
      );
    },
    [columns, prepareRow, rows],
  );

  return columns.length === 0 ? (
    <></>
  ) : (
    <>
      {message && <FlashMessage message={message} flashTrigger={flashTrigger} />}
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openForm}>
        <DataForm
          setMessage={setMessage}
          flashTrigger={flashTrigger}
          setFlashTrigger={setFlashTrigger}
          dataType={dataType}
          openForm={openForm}
          setOpenForm={setOpenForm}
          currentId={currentId}
          setCurrentId={setCurrentId}
          allData={data}
        />
      </Backdrop>
      <DivStyles>
        <TableContainer component={Paper} sx={{ marginBottom: '40px' }}>
          <Grid container spacing={2} sx={{ paddingLeft: '16px' }}>
            <Grid item xs={12} sx={{ marginTop: '15px', marginBottom: '15px' }}>
              {/* 検索 */}
              <Filter filter={globalFilter} setFilter={setGlobalFilter} />
              　　CSV:
              <CSVLink data={prepareCsvData()} filename={prepareCsvFileName(dataTypeJP, data)} target="_blank">
                <Download fontSize="large" sx={{ marginLeft: '8px' }} />
              </CSVLink>
            </Grid>
            <div width="100%" {...getTableProps()} style={{ marginBottom: '0px' }} className="table sticky">
              <FixedSizeList className={'fixed-size-list'} style={{ overflowX: 'hidden' }} height={433} itemCount={rows.length} itemSize={50} width="100%" innerElementType={TableWrapper}>
                {RenderRows}
              </FixedSizeList>
            </div>
          </Grid>
        </TableContainer>
      </DivStyles>

      {dialog.isOpen && (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <ConfirmDialog
            onDialog={confirmDialog}
            message={dialog.message}
            dataIdentifier={dialog.dataIdentifier}
            setDialog={setDialog}
            setMessage={setMessage}
            setFlashTrigger={setFlashTrigger}
            flashTrigger={flashTrigger}
            deleteData={deleteData}
          />
        </Backdrop>
      )}
    </>
  );
};

export default DataTable;
