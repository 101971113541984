import React, { useState, useMemo, useRef, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Marker, Popup } from 'react-leaflet';
import { RiSave3Fill } from 'react-icons/ri';
import { BsFillPinMapFill } from 'react-icons/bs';
import { FaUndoAlt } from 'react-icons/fa';
import { IconButton, Tooltip } from '@mui/material';

import { updatePickUpPoint } from '../../../actions/pickUpPoints';
import { redIcon, blueIcon } from '../MarkerIcons.js';
import { ERROR, SUCCESS_EDIT } from '../../../constants/flashMessages';
import { DB_SUCCESS } from '../../../constants/dbResultCodes';
import { LatLng } from 'leaflet';

const PickUpPointMapDraggableMarker = ({ pickUpPoint, setFlashTrigger, setMessage, flashTrigger, setIsDragging, isDragging }) => {
  const dispatch = useDispatch();
  const row = useSelector((state) => (pickUpPoint._id ? state.data.find((d) => d._id === pickUpPoint._id) : null));
  const initialPosition = new LatLng(row.pickUpPointLat, row.pickUpPointLng);

  useEffect(() => {
    const currentPosition = new LatLng(row.pickUpPointLat, row.pickUpPointLng);
    setPosition(currentPosition);
  }, [row.pickUpPointLat, row.pickUpPointLng]);

  const [draggable, setDraggable] = useState(false);
  const [position, setPosition] = useState(initialPosition);
  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition(marker.getLatLng());
        }
      },
    }),
    [],
  );

  const toggleDraggable = useCallback(() => {
    setDraggable((d) => !d);
    setIsDragging(!isDragging);
  }, [setIsDragging, isDragging]);

  const undoDrag = () => {
    toggleDraggable();
    setPosition(initialPosition);
  };
  const saveNewPosition = async () => {
    const updatedPickUpPoint = JSON.parse(JSON.stringify(pickUpPoint));
    updatedPickUpPoint.pickUpPointLat = position.lat;
    updatedPickUpPoint.pickUpPointLng = position.lng;
    const result = await dispatch(updatePickUpPoint(pickUpPoint._id, updatedPickUpPoint));
    toggleDraggable();
    let message;
    if (result === DB_SUCCESS) {
      message = SUCCESS_EDIT;
    } else {
      message = ERROR;
      undoDrag();
    }
    setMessage(message);
    setFlashTrigger(flashTrigger + 1);
    setIsDragging(false);
  };

  const ToolTipIconButton = () => {
    return (
      <>
        <Tooltip title={isDragging ? draggable ? <div>保存</div> : <div>別マーカが移動中</div> : <div>マーカ移動</div>}>
          <span>
            <IconButton style={{ color: isDragging && !draggable ? 'gray' : 'black' }} size="small" onClick={draggable ? saveNewPosition : toggleDraggable} disabled={isDragging && !draggable}>
              {draggable ? <RiSave3Fill /> : <BsFillPinMapFill />}
            </IconButton>
          </span>
        </Tooltip>
        {draggable ? (
          <Tooltip title={<div>元に戻す</div>}>
            <IconButton style={{ color: 'black' }} size="small" onClick={undoDrag}>
              <FaUndoAlt />
            </IconButton>
          </Tooltip>
        ) : (
          ''
        )}
      </>
    );
  };

  return (
    <Marker draggable={draggable} eventHandlers={eventHandlers} position={position} ref={markerRef} icon={draggable ? redIcon : blueIcon}>
      <Popup minWidth={90}>
        <ToolTipIconButton sx={{ marginBottom: '5px' }} /> {pickUpPoint.pickUpPointName}
      </Popup>
    </Marker>
  );
};

export default PickUpPointMapDraggableMarker;
